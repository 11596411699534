import { Checkbox, Snackbar, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import './MyBookings.scss';
import RentHeaderDto from '../../dtos/RentHeaderDto'
import { CarType, RentStatus, RentType } from '../../dtos/PriceData';
import { axiosInstance } from '../../config/Axios.config';
import Alert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router';
import autoTable from 'jspdf-autotable';
import MetaTags from 'react-meta-tags';


export default function MyBookings() {

    const [rows, setRows] = useState<RentHeaderDto[]>([])
    const [error, setError] = useState("");
    const [init, setInit] = useState(true);
    const [exportPdf, setExportPdf] = useState(false);
    const [exportList, setExportList] = useState<string[]>([]);
    const [detailsRedirect, setDetailsRedirect] = useState("");
    const { jsPDF } = require("jspdf");

    useEffect(() => {
        if(detailsRedirect !== "") {
            window.scroll({ top: 0 });
            setDetailsRedirect("");
        }
        if(init) {
            axiosInstance.defaults.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
            axiosInstance.get<RentHeaderDto[]>("profile/bookings")
                .then(res => {
                    res.data.forEach(r => r.date = new Date(r.date))
                    setRows(res.data);
                })
                .catch(err => {
                    if (err.response?.status === 404) {
                        setError("Unknown error occured");
                    }
                    else {
                        if(err.response?.status === 419) {
                            localStorage.clear();
                            window.location.href = "/";
                            return;
                          }
                        if (err.response?.data !== undefined) {
                        setError(err.response.data.message);
                        }
                        else {
                        setError("Cannot reach server");
                        }
                    }
                })
            setInit(false);
        }
    })

    const onExport = () => {
        if(!exportPdf){
            setExportPdf(true);
        }
        else {
            if(exportList.length === 0) {
                setExportPdf(false);
                return;
            }
            const doc = new jsPDF();
            doc.setFont("helvetica", "bold");
            doc.setFontSize(20);
            doc.text("Simber Chauffeur Services - Exported Trips", 31, 20);
            doc.setFontSize(12);
            
            doc.text("Simber Limited", 15, 34);
            doc.text("1st Floor, The Mille, 1000 Great West Road", 15, 40);
            doc.text("London, TW8 9DW", 15, 46);
            doc.text("+ 44 (0) 203 983 5383", 15, 52);
            doc.text("info@simber.co.uk", 15, 58)

            var generateData = function() {
                var result = [];
                var list = rows.filter(x => exportList.some(y => y === x.id));
                for (var i = 0; i < list.length; i += 1) {
                    result.push([
                        (list[i].date.getFullYear() + "." + ((list[i].date.getMonth()+1 < 10) ? ("0" + (list[i].date.getMonth()+1)) : (list[i].date.getMonth()+1)) + "." + list[i].date.getDate()),
                        list[i].origin,
                        (list[i].destination === "" ? "-" : list[i].destination),
                        (list[i].carType === CarType.SevenSeater ? "Premium MPV" : CarType[list[i].carType]),
                        (list[i].rentType === RentType.ByTheHour ? "Hourly" : "One way"),
                        ("£" + list[i].price.toString())
                    ]);
                }
                return result;
              };
              
              var createHeaders = function(keys:any) {
                var result = [];
                for (var i = 0; i < keys.length; i += 1) {
                  result.push({
                    id: keys[i],
                    name: keys[i],
                    prompt: keys[i],
                    width: 85,
                    align: "center",
                    padding: 0
                  });
                }
                return result;
              }
              
              var headers = [
                "Date",
                "From",
                "Destination",
                "Vehicle",
                "Journey",
                "Price"
              ];
              
              //doc.table(1, 50, generateData(), headers, { autoSize: true });
              autoTable(doc, {
                  head: [headers],
                  body: generateData(),
                  startY: 70
              })

            var allPrice = 0;
            rows.map(x => {
                if(exportList.some(y => y === x.id)) {
                    allPrice += x.price;
                }
            });
            doc.setFont("helvetica", "normal");
            doc.text("Total: £ " + allPrice, 172, doc.autoTable.previous.finalY + 8)

            doc.save("trip-exports.pdf");
            
            setExportList([]);
            setExportPdf(false);
        }
    }

    const changeExport = (id: string) => {
        var temp = exportList;
        var checked = exportList.find(v => v === id);
        if( checked !== undefined) {
            temp = temp.filter(v => v !== id)
        } else {
            temp.push(id);
        }
        setExportList(temp);
    }

    if(detailsRedirect !== "") {
        return (<Redirect push to={"/profile/bookings/" + detailsRedirect} />)
    }
    else {
        return (
            <div className="bookings-page-wrapper">
                <MetaTags>
                    <link rel="canonical" href="https://www.simber.co.uk/profile/bookings" />
                </MetaTags>
                <div className="mobil-wrapper">
                    {rows.map(row => {
                        return (
                            <div className="booking-card">
                                {exportPdf &&
                                    <div className="export">
                                        <Checkbox onClick={() => changeExport(row.id)}  className="checkbox"/>
                                    </div>
                                }
                                <div className="data">
                                    <span className="date">{row.date.getDate() + "." + ((row.date.getMonth()+1 < 10) ? ("0" + (row.date.getMonth()+1)) : (row.date.getMonth()+1)) + "." +  row.date.getFullYear()}</span>
                                    <span className="origin">{row.origin}</span>
                                    {row.rentType === RentType.Oneway &&
                                        <React.Fragment>
                                            <img src={require("../../images/pictograms/half_arrow.png")} />
                                            <span>{row.destination}</span>
                                        </React.Fragment>
                                    }
                                    <a onClick={() => { setDetailsRedirect(row.id)}}>view more {'>'}</a>
                                    {row.status === RentStatus.Canceled &&
                                        <div className="cancelled">
                                            cancelled
                                        </div>
                                    }
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="table-wrapper">
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {exportPdf && <TableCell></TableCell>}
                                <TableCell>Date</TableCell>
                                <TableCell>From</TableCell>
                                <TableCell></TableCell>
                                <TableCell>Destination</TableCell>
                                <TableCell>Vehicle Type</TableCell>
                                <TableCell>Journey Type</TableCell>
                                <TableCell>Price</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody className="table-body">
                            {rows.map(row => {return (
                                <TableRow>
                                    {exportPdf &&
                                        <TableCell>
                                            <Checkbox onClick={() => changeExport(row.id)}  className="checkbox"/>
                                        </TableCell>
                                    }
                                    <TableCell>
                                        <div className="table-row-content">
                                            {row.date.getDate() + "." + ((row.date.getMonth()+1 < 10) ? ("0" + (row.date.getMonth()+1)) : (row.date.getMonth()+1)) + "." +  row.date.getFullYear()}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="table-row-content">
                                            {row.origin}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="table-row-content">
                                            <img src={require("../../images/pictograms/half_arrow.png")} />
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="table-row-content">
                                            {row.destination === "" ? "-" : row.destination}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="table-row-content">
                                            {row.carType === CarType.SevenSeater ? "Premium MPV" : CarType[row.carType]}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="table-row-content">
                                            {row.rentType === RentType.ByTheHour ? "Hourly" : "One way"}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        <div className="table-row-content">
                                            {row.price + " GBP"}
                                        </div>
                                    </TableCell>
                                    <TableCell>
                                        {row.status === RentStatus.Canceled ||
                                            <div className="table-row-content view-more">
                                                <a onClick={() => { setDetailsRedirect(row.id)}}>view more <img src={require("../../images/pictograms/arrow.png")} /></a>
                                            </div>
                                        }
                                        {row.status === RentStatus.Canceled &&
                                            <div className="table-row-content cancelled">
                                                cancelled
                                            </div>
                                        }
                                    </TableCell>
                                </TableRow>
                            )})}
                        </TableBody>
                    </Table>
                </div>
                <span onClick={() => onExport()} className="export-link">{exportPdf ? "export selected" : "export to pdf"}</span>
                <Snackbar open={error !== ""}>
                    <Alert onClose={() => setError("")} severity="error">
                        {error}
                    </Alert>
                </Snackbar>
            </div>
        )
    }
}