import React, { useState } from "react";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Redirect } from "react-router";
import { axiosInstance } from "../config/Axios.config";
import UserRegisterDto from "../dtos/UserRegisterDto"
import { Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import './RegisterPage.css'
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import './RegisterPage.scss'
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from "./BreadCrumbs";

export default function RegisterPage() {
  const [redirect, setRedirect] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [emailRe, setEmailRe] = useState("");
  const [pass, setPass] = useState("");
  const [passRe, setPassRe] = useState("");
  const [phone, setPhone] = useState("");
  const [spam, setSpam] = useState(false);
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(false);
  const [eighteen, setEighteen] = useState(false);
  const [errorMsg, setError] = useState("");

  const tryRegister = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    var errors = "";
    var reg = new RegExp("^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$");
    if (!reg.test(email))
      errors += "Invalid email format.\n"
    if(name === "")
      errors += "Name is required.\n"
    if(email !== emailRe)
      errors += "Email and confirmation doesn't match.\n"
    if(pass !== passRe)
      errors += "Password and confirmation doesn't match.\n"
      if(!eighteen)
      errors += "You must be 18 on older to use our services."
    if(errors !== ""){
      setError(errors)
      setOpen(true);
      return;
    }

    var data: UserRegisterDto = {
      Email: email,
      EmailRe: emailRe,
      Password: pass,
      AllowSpam: spam,
      Name: name,
      PasswordRe: passRe,
      Phone: phone
    }
    axiosInstance.post("user/register",data)
      .then(response => {
        setSuccess(true);
      })
      .catch(error => {
        if(error.response?.status === 404){
          setError("Unknown error occured");
        }
        else {
          if(error.status === 419) {
            localStorage.clear();
            window.location.href = "/";
            return;
        }
          if(error.response?.data !== undefined){
            setError(error.response.data.message);
          }          
          else{
            setError("Cannot reach server");
          }
        }        
        setOpen(true); 
      })
  }

  const breadcrumbList: IBreadcrumb[] = [
    {
      description: "Register",
      url: "https://www.simber.co.uk/register"
    },
  ]

  if(success){
    return <Redirect push to="/login" />;
  } else {
  if (redirect) {
    return <Redirect push to="/" />;
  } else {
    return (
      <div className="wrapper">
        <MetaTags>
            <meta name="description" content="To complete a booking for our chauffeur transportation services, please register here to receive and view all the information relating to your chauffeur hire." />
            <title>Register | Simber</title>
            <link rel="canonical" href="https://www.simber.co.uk/register" />
        </MetaTags>
        <HeaderComponent />
        <main>
          <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            <div className="register-card">
              <Typography className="sign-in-header" component="h1" variant="h5">
                Create your Account
              </Typography>
              <span>Enter your credentials to get started.</span>
              <form noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      autoComplete="name"
                      name="Name"
                      variant="filled"
                      required
                      fullWidth
                      id="Name"
                      label="Name"
                      autoFocus
                      value={name}
                      onChange={(e) => setName(e.currentTarget.value)}
                    />
                  </Grid>                
                  <Grid item xs={12}>
                    <TextField
                      variant="filled"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                      value={email}
                      onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="filled"
                      required
                      fullWidth
                      id="emailRe"
                      label="Confirm Email Address"
                      autoComplete="none"
                      name="emailRe"
                      value={emailRe}
                      onChange={(e) => setEmailRe(e.currentTarget.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="filled"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      type="password"
                      id="password"
                      autoComplete="current-password"
                      value={pass}
                      onChange={(e) => setPass(e.currentTarget.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="filled"
                      required
                      fullWidth
                      name="passwordRe"
                      label="Password confirmation"
                      type="password"
                      id="passwordRe"
                      value={passRe}
                      onChange={(e) => setPassRe(e.currentTarget.value)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <PhoneInput
                      regions={'europe'}
                      country={'gb'}
                      enableSearch
                      onChange={(val) => setPhone(val)}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox className="checkbox" value="allowExtraEmails" checked={spam} onChange={(e) => setSpam(!spam)}/>
                      }
                      label="I want to receive inspiration, marketing promotions and updates via email."
                    />
                    <FormControlLabel
                        className="eighteen"
                        control={
                          <Checkbox className="checkbox" checked={eighteen} onChange={() => setEighteen(!eighteen)}/>
                        }
                        label="I am 18 years or older."
                      />
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={(e) => tryRegister(e)}
                >
                  Sign Up
                </Button>
                <div>
                  <Grid item>
                    <span>Already have an account? <Link href="https://www.simber.co.uk/login">Sign in</Link></span>
                  </Grid>
                </div>
              </form>
            </div>
          </Container>
          <Typography className="feature-card-header" component="h1" variant="h5">
            We are here for you.
          </Typography>
          <div className="features-card">
            <div className="feaute">
              <span className="feature-header">Always there</span>
              <span className="feautre-text">We are on hand to assist with any special requests or complex transportation requirements. Tailor made journeys for you.</span>
            </div>
            <div className="feaute">
              <span className="feature-header">Transparent pricing</span>
              <span className="feautre-text">At SIMBER our rates are transparent, the price provided and confirmed relevant to your service includes tolls, fees and parking charges.</span>
            </div>
            <div className="feaute">
              <span className="feature-header">Trusted Service</span>
              <span className="feautre-text">Our team including our chauffeurs are professional, attentive, discrete and welcoming. We strive to ensure you receive a service that is second to none.</span>
            </div>          
            <div className="feaute">
              <span className="feature-header">Seamless Service</span>
              <span className="feautre-text">Our team including our chauffeurs are professional, reliable, attentive and discrete . With a well-presented fleet of vehicles we strive to ensure you always receive a service that is second to none. </span>
            </div>
            <div className="feaute">
              <span className="feature-header">Complimentary waiting times</span>
              <span className="feautre-text">Take the time you need, our bookings include complimentary waiting times. Airport transfers include 60 minutes of complimentary wait time, 15 minutes on all other journeys. Additional waiting time if required will be charged in 15 minute intervals.</span>
            </div>
            <div className="feaute">
              <span className="feature-header">Peace of Mind</span>
              <span className="feautre-text">Relax and enjoy your journey! At SIMBER we track our clients' flight, regardless of whether you are landing early or delayed we will always have a chauffeur and an immaculate vehicle waiting for you. </span>
            </div>
            <div className="feaute">
              <span className="feature-header">Booking Confirmations</span>
              <span className="feautre-text">Once your journey is booked you receive an instant confirmation email. Prior to the journey another email will follow confirming your chauffeurs details.</span>
            </div>
          </div>
        </main>
        <Snackbar
          open={open}
          onClose={() => setOpen(!open)}
        >
          <Alert onClose={() => setOpen(!open)} severity="error" className="error-dialog">
            {errorMsg}
          </Alert>
        </Snackbar>
        <FooterComponent />
      </div>
    );
    }
  }
}
