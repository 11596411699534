import { Container } from '@material-ui/core';
import React from 'react';
import StructuredBreadcrumb, { IBreadcrumb } from '../../BreadCrumbs';
import FooterComponent from '../../FooterComponent';
import HeaderComponent from '../../HeaderComponent';
import MetaTags from 'react-meta-tags';
import './AirportPageHeathrow.scss'

export default function AirportPageHeathrow() {
    
    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
          description: "Airport Transfers",
          url: "https://www.simber.co.uk/services/airport-transfers"
        },
        {
          description: "Luxury Airport Transfer to Heathrow",
          url: "https://www.simber.co.uk/services/airport-transfers/heathrow"
        },
    ]

    return (
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="Experience the epitome of luxury with a premium airport transfer to Heathrow. Book a comfortable and stylish journey with Simber for the ultimate experience." />
                <title>Luxury Airport Transfer to Heathrow | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/airport-transfers/heathrow" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="md" className="container">
                        <div className="heathrow-wrapper service-picture"></div>
                        <div className="content">
                            <h1>Exceptional Luxury Airport Transfer Heathrow with Simber</h1>
                            <p>Heathrow is the most famous commercial airport in London, but also throughout the UK. Very often, flights from other countries are directly to this airport. Do you also arrive at Heathrow or need to depart from there? Find out how chauffeur service can help you have a comfortable and stress-free experience:</p>
                            <div className="devider"></div>
                            <h2>How do Airport Transfers Work?</h2>
                            <p>As a service, airport transfers are similar to meet-and-greet, but in a more luxurious way. Our team arranges a stress-free pick-up from Heathrow, leaving you free to focus on your journey ahead. We can do the same if you need to travel from Heathrow to another destination, as you'll need efficient transportation.</p>
                            <h3> Seamless and Stress-Free Transportation</h3>
                            <p>We prioritize comfort and convenience when it comes to any kind of <a href="https://www.simber.co.uk/services/airport-transfers">luxury airport transfer</a> to Heathrow airport. From the moment you book with Simber, our trained and dedicated staff is ready to provide exceptional service so you can reach your destination with ease.</p>
                            <div className="devider"></div>
                            <h2>Luxury Transfer to Heathrow Airport</h2>
                            <p>How to experience the epitome of style and comfort with our luxury transfer service to Heathrow? We provide elegant and comfortable vehicles driven by professionally trained chauffeurs. Our transportation experience exceeds your expectations, ensuring you travel in luxury from the moment we pick you up until you reach your destination.</p>
                            <h3>Indulge in Unparalleled Style and Comfort</h3>
                            <p>Imagine having time to relax once you arrive at Heathrow without worrying about your luggage and personal belongings. Our professional chauffeurs will take care of every detail, ensuring your journey is not only luxurious but also efficient. And you have only a few simple things to do - sit back and enjoy a first-class airport transfer experience.</p>
                            <div className="devider"></div>
                            <h2>Transfers from Hotel to Airport</h2>
                            <p>Leave the hassle of getting to the airport from your hotel in our hands. As we can pick you up from Heathrow, we also do the same from the hotel or accommodation to the airport.</p>
                            <h3>Effortless Departures from Your Accommodation</h3>
                            <p>Our tailored hotel-to-airport service lets you leave the stress of arranging transportation out of your plans. Our chauffeurs will arrive promptly at your accommodation, assist with your luggage, and ensure a smooth and timely transfer to the airport. Simber's goal is to make your arrival and departure as effortless as possible, so book your transfer now to elevate your journey.</p>
                        </div>
                    </Container>
                </main>
                <FooterComponent/>
            </div>
        </div>
    );
}