import { Container } from '@material-ui/core';
import React, { useEffect } from 'react';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import { AccordionComponent } from './AccordionComponent';
import './EventChauffeuringPage.scss'
import MetaTags from 'react-meta-tags';
import $ from 'jquery';
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';

export default function EventChauffeuringPage() {

    useEffect(() => {
        $(document).ready(function () {

            $('.fadeHover').hover(
                function () {
                    $('.fadeHover').not(this).fadeTo('fast', 0.3);
                },
                function () {
                    $('.fadeHover').fadeTo('fast', 1);
                }
            );
        });
    })

    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
          description: "Events Chauffeur Services",
          url: "https://www.simber.co.uk/services/events-chauffeurs"
        },
    ]

    return (
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="Welcome to Simber, your premier destination for event chauffeur services in London and the UK" />
                <title>Event Chauffeur Services: Elevate Your Occasions with Unparalleled Luxury</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/events-chauffeurs" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="md" className="container">
                        <img onClick={() => window.scrollTo({top: 0, behavior: "smooth"})} className="top-img" src={require("../../images/pictograms/top_arrow.png")}/> 
                        <div className="event-wrapper service-picture"></div>
                        <div className="content">
                            <h1>Events Chauffeur Services </h1>
                            <h2>Enjoy your journey in stylish and comfortable environment to & from events with Simber’s exclusive event chauffeur services </h2>
                            <p>Simber prides itself on being able to provide highly <a href="https://www.simber.co.uk/">professional chauffeur services</a> for events within London or the UK. If you are taking part in an event or organizing one of your own and are in need of exclusive chauffeur transportation services, get in touch with Simber for the ultimate enjoyable and worry-free experience which will be provided to you and your guests. </p>
                            <p><button className="primary-button" onClick={() => window.location.href="https://www.simber.co.uk"}>Book Now</button></p>
                            <img alt="Exclusive cars waiting for passengers in front of event hosting establishment." className="large-image" src={require("../../images/servicepages/event_1.png")}/>
                            <p>In addition to being great drivers, our trusted event chauffeurs will be on hand to assist you and your guests with any personal belongings or equipment you may have. At Simber we are able to organize multiple vehicles, depending on the number of guests including other special requests you may have. In order to ensure all requirements are met, please use the <a href="https://www.simber.co.uk/contact" rel="nofollow">contact form here </a> to tell us all the details of your transport needs. If an event is taking place and a higher number of chauffeured vehicles are requested, Simber will provide a coordinator to lead the event chauffeur team as well as liaise with your representative and save you the hassle of logistics.</p>
                            <p>Our range of vehicles include the stylish Mercedes E-Class, the ever so elegant Mercedes S-class as well as the reputable Mercedes V-class. The Mercedes V-Class is no ordinary multi-purpose vehicle. In fact, along with the Mercedes S-Class, it is the top favorite for clients traveling to events due to its ample space, style and outstanding comfort.</p>
                            <p>If you want to make an impression at your event, Simber offers the elegant Range Rover Autobiography Long-Wheel as well as the extremely luxurious Rolls Royce Ghost. So, whether you need a sporting event chauffeur or a corporate event chauffeur hire, it doesn’t matter, Simber always provides the best chauffeur vehicles. </p>
                            <p>For groups of 8 or more passengers traveling in the same vehicle Simber can provide chauffeur driven executive minibuses of all sizes to suit your requirements. Therefore, our event chauffeur service is always adapted to your needs!</p>
                            <div className="devider"></div>
                            <div className="picture-section big">
                                <img alt="Photo of a horse race, showing three horses and their riders on the track." src={require("../../images/servicepages/event_2.png")}/>
                                <div className="content">
                                    <h3>Visit Sports and Corporate Events with Simber </h3>
                                    <p>Regardless of the number of passengers attending an event in London, or the rest of the UK, our chauffeurs will make sure you have a comfortable, stress-free journey and, most importantly, arrive on time. So, our event chauffeur London or one for any other event will always give you a smooth and safe ride. </p>
                                    <p>Take part in the biggest horse racing events UK like Horse Racing at Royal Ascot London in Windsor, London Fashion Week events, Formula One at Silverstone Circuit, Tennis tournaments at Wimbledon, Football at Wembley Stadium, Lord's Cricket Ground events, NFL London games, or travel to concerts hosted by the biggest music stars in premium luxury and style. For example, here you can see some top-rated events and chauffeur services </p>
                                </div>
                            </div>
                            <p><button className="primary-button" onClick={() => window.location.href="https://www.simber.co.uk"}>Book Now</button></p>
                            <h3 className='small'>Royal Ascot Chauffeur Service</h3>
                            <p>Royal Ascot 2022 as every other year will provide the most unique event on the sporting calendar. When it comes to horse racing UK, this horse racing event is more than special. Queen Ann founded the Royal Ascot map by creating the Ascot Races in 1711. Therefore, with our horse racing chauffeur services you can enjoy the glamorous Race day and spend quality time with your family and friends.</p>
                            <h3 className='small'>Luxury Chauffeur Driven Cars For Wimbledon</h3>
                            <p>Another fantastic event for which you can use Wimbledon tennis chauffeur service and enjoy one of the biggest sporting events in the world. Since this is the most elegant tennis tournament ever, a luxurious chauffeur driven car for Wimbledon is what you need in order to arrive in style.</p>
                            <h3 className='small'>Luxury Chauffeur Services for London Fashion Week</h3>
                            <p>London Fashion Week Chauffeur Services are also available for you, your family, and friends if you want to enjoy one of the most luxurious events in a luxurious way. Here arriving in style is even more important, and Simber can provide you with some of the best vehicles driven by the best chauffeurs for the London Fashion Week 2022, as well as any other in the future.</p>
                            <h3 className='small'>NFL London Games Event</h3>
                            <p>Hiring a chauffeur for NFL London will make everything a lot easier since these types of events are always accompanied by a large amount of people, enormous crowds, and driving, parking, and everything in between is extremely challenging. So, why bother when you can hire an event chauffeur and let them take care of everything. </p>
                            <h3 className='small'>Lords Cricket Ground Chauffeur</h3>
                            <p>Or maybe you are a fan of Lord's cricket ground, and Simber will once again provide you with the best possible event chauffeur services. As you can see, whether you like some of these fantastic events or live concerts, we will always provide you with luxurious vehicles driven by professional chauffeurs.</p>
                            <p>You, your family, and friends will spend amazing and unforgettable time at your chosen event with Simber as your chauffeur-service provider partner. It is up to you to sit, relax and enjoy the excitement while our chauffeurs take care of the rest.</p>
                            <div className="devider"></div>
                            <h2 className="faq-section-header">Event Chauffeur FAQ</h2>
                            <div className="faq-wrapper" itemScope itemType="https://schema.org/FAQPage">
                                <AccordionComponent 
                                    question="I’m planning a big event and need multiple vehicles, how do I get in touch? " 
                                    answer="For any major or social events our advice is to complete our contact form on the Contact Us page describing your requirements in detail. We are always more than happy to assist you any way we can and a member of our team will be in touch to plan the finer details. For large events where there are a high number of chauffeured vehicles requested, Simber will provide a coordinator to lead the event chauffeur team as well as liaise with your representative and save you the hassle of logistics."
                                />
                                <AccordionComponent 
                                    question="Can you arrange for our chauffeur to pick up my friends, prior to picking me up?" 
                                    answer="Yes of course, when confirming your booking please be sure to advise all of the pick up locations and times, and our events chauffeurs will pick up your friends. "
                                />
                                <AccordionComponent 
                                    question="Will my chauffeur wait for me during the entire event?" 
                                    answer="Your corporate events chauffeur will be at your disposal during the entire event, available to run an errand or transport guests, family or friends. The same goes for any other event, of course. "
                                />
                                <AccordionComponent 
                                    question="I may need the chauffeur for a few extra hours, who do I contact?" 
                                    answer="Should the vehicle/s be required for additional time in general this is no problem, please communicate this with the chauffeur, the office or in the event that there are multiple vehicles on site please advise the Simber Coordinator. Please note that any excess time requested will be charged at the hourly rate which is divided in 15 minute intervals. "
                                />
                                <AccordionComponent 
                                    question="Is the price calculated per hour and is it fixed?" 
                                    answer="Event bookings are calculated on the length of time the chauffeur/vehicle is hired for the booking and our prices are fixed. However, should the booking exceed the agreed time please note that charges will apply at the chauffeur hourly rate which is divided in 15 minute intervals."
                                />
                                <AccordionComponent 
                                    question="When do I pay for my booking?" 
                                    answer="For all bookings such as social or corporate events including weddings we require a 25% deposit, with the remaining balance due 14 days prior to the booking."
                                />
                                <AccordionComponent 
                                    question="Will the car have umbrellas available?" 
                                    answer="Rain or shine, we always have you covered. Together with the vehicle essentials, every chauffeur has an umbrella."
                                />
                                <AccordionComponent 
                                    question="Do additional charges apply for  holidays, Christmas. New Year's day or New Year's Eve?" 
                                    answer="Bookings carried out on Christmas Day, Boxing Day, New Years Eve (from 18:00) and all day New Years Day will be subject to an “unsocial hours” surcharge of 100% on all journeys."
                                />
                                <AccordionComponent 
                                    question="What is your cancellation policy for major corporate or social events?" 
                                    answer="In the event that a major corporate or social event is cancelled within 21 days of the start of the booking hire, the 25% deposit taken will not be refunded. If within seven days of a major corporate or social event is cancelled by the client, the full booking amount will be charged."
                                />
                            </div>
                        </div>
                    </Container>
                </main>
                <FooterComponent/>
            </div>
        </div>
    )
}