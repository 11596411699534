import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { Redirect } from "react-router";
import { axiosInstance } from "../config/Axios.config";
import UserLoginDto from '../dtos/UserLoginDto'
import Alert from "@material-ui/lab/Alert";
import { Dialog, DialogActions, DialogContent, DialogContentText, Snackbar } from "@material-ui/core";
import { connect, useDispatch } from "react-redux";
import { saveToken, UserActionTypes } from "../redux/actions";
import { bindActionCreators, Dispatch } from 'redux';
import { RootState } from "../redux/reducers/rootReducer";
import HeaderComponent from "../Components/HeaderComponent"
import FooterComponent from "./FooterComponent";
import './LoginPage.scss'
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from "./BreadCrumbs";

interface Mapped {
  price?: number;
  token: string;
}

function LoginPage(props: Mapped) {
  const [redirect, setRedirect] = useState(false);
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [open, setOpen] = useState(false);
  const [errorMsg, setError] = useState("");
  const [forgotPass, setforgotPass] = useState(false);
  const [forgotPassSuccess, setforgotPassSuccess] = useState(false);
  const [emailConfirmOpen, setEmailConfirmOpen] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const dispatchH = useDispatch();

  const tryLogin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    var data: UserLoginDto = {
      Email: email,
      Password: pass
    }
    axiosInstance.post("user/login", data)
      .then(response => {
        axiosInstance.defaults.headers["Authorization"] = "Bearer " + response.data
        dispatchH(saveToken(response.data));
        setRedirect(true);
      })
      .catch(error => {
        if (error.response?.status === 404) {
          setError("Unknown error occured");
        }
        else {
          if(error.status === 419) {
            localStorage.clear();
            window.location.href = "/";
            return;
          }
          if(error.response?.status === 401) {
            setEmailConfirmOpen(true);
            return;
          }
          else {
            if (error.response?.data !== undefined) {
              setError(error.response.data.message);
            }
            else {
              setError("Cannot reach server");
            }
          }
        }
        setOpen(true);
      })
  }

  const sendResetEmail = () => {
    axiosInstance.post("user/resetPass/getlink?email=" + email)
      .then(response => {
        setforgotPassSuccess(true);
        setforgotPass(false);
      })
      .catch(error => {
        if (error.response?.status === 404) {
          setError("Unknown error occured");
        }
        else {
          if(error.status === 419) {
            localStorage.clear();
            window.location.href = "/";
            return;
          }
          if (error.response?.data !== undefined) {
            setError(error.response.data.message);
          }
          else {
            setError("Cannot reach server");
          }
        }
        setOpen(true);
      })
  }
 
  const resendConfirmEmail = () => {
    axiosInstance.post("user/confirmEmail/generate?email=" + email)
      .then(res => {
        setEmailConfirmOpen(false);
        setEmailSent(true);
      })
      .catch(err => {
        if(err.response?.status === 419) {
          localStorage.clear();
          window.location.href = "/";
          return;
        }
        if (err.response?.data !== undefined) {
          setError(err.response.data.message);
        }
        else {
          setError("Cannot reach server");
        }
      })
  }

  const breadcrumbList: IBreadcrumb[] = [
    {
      description: "Sign in",
      url: "https://www.simber.co.uk/login"
    },
  ]

  if (redirect) {
    return (<Redirect push to="/" />)
  }
  else
    return (
      <div className="wrapper">
        <MetaTags>
            <meta name="description" content="To complete a booking for our chauffeur transportation services, please sign in here to receive and view all the information relating to your chauffeur hire." />
            <title>Sign in | Simber</title>
            <link rel="canonical" href="https://www.simber.co.uk/login" />
        </MetaTags>
        <HeaderComponent />
        <main>
          <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
          <Container component="main" maxWidth="sm">
            <CssBaseline />
            {(forgotPass || forgotPassSuccess) ||
              <div className="login-card">
                <Typography className="sign-in-header" component="h1" variant="h5">
                  Sign in
                </Typography>
                <span>Enter your email to log in or create an account</span>
                <form noValidate>
                  <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => { setEmail(e.currentTarget.value) }}
                  />
                  <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    value={pass}
                    onChange={(e) => { setPass(e.currentTarget.value) }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={(e) => tryLogin(e)}
                  >
                    login
                  </Button>              
                </form>
                <Link onClick={() => setforgotPass(true)}>Forgot password?</Link>
                <span>Dont have an account? <Link href="https://www.simber.co.uk/register">Sign up</Link></span>
              </div>
            }
            {forgotPass &&
              <div className="login-card">
                <Typography className="sign-in-header" component="h1" variant="h5">
                  Forgot Password?
                </Typography>
                <span>Check your email and click on the link we've sent you.</span>
                <div>
                  <TextField
                    variant="filled"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => { setEmail(e.currentTarget.value) }}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    onClick={() => sendResetEmail()}
                  >
                    Send
                  </Button>
                  <Link onClick={() => setforgotPass(false)}>Log in</Link>
                </div>
              </div>
            }
            {forgotPassSuccess &&
              <div className="login-card">
                <span className="success">Email sent!</span>
                <span className="didnt-get-email-link">I didn't get any email? <a onClick={() => {setforgotPass(true); setforgotPassSuccess(false)}}>Send again</a></span>
              </div>
            }
          </Container>
        </main>
        <Snackbar
          open={open}
          onClose={() => setOpen(!open)}
        >
          <Alert onClose={() => setOpen(!open)} severity="error">
            {errorMsg}
          </Alert>
        </Snackbar>
        <Snackbar
          open={emailSent}
          onClose={() => setEmailSent(false)}
        >
          <Alert onClose={() => setEmailSent(false)} severity="success">
            <span>Email confirmation email sent!</span>
          </Alert>
        </Snackbar>
        <FooterComponent />
        <Dialog
          open={emailConfirmOpen}
          className="email-confirm-dialog"
        >
          <DialogContent>
            <DialogContentText>
              <span>You have to confirm your email address in order to log in!</span>
              <span>Can't find your confirmation email?</span>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => resendConfirmEmail()}>resend email</Button>
            <Button onClick={() => setEmailConfirmOpen(false)}>ok</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<UserActionTypes>) =>
  bindActionCreators(
    {
      saveToken
    },
    dispatch
  );

const mapStateToProps = (state: RootState): Mapped => {
  return {
    price: state.summary.price,
    token: state.user.token
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginPage);
