import React from 'react';
import { mainEmail, phone, lostAndFoundEmail, redirectToFb, redirectToInsta } from '../config/Contacts'
import './FooterComponent.scss'

function FooterComponent(){

    const isServicePage = window.location.href.includes("services") || window.location.href.includes("blog");

    const onTopClick = () => {
        window.scroll({top: 0, behavior: 'smooth'})
    }

    return (        
        <footer id="contact" className="footer">
            <div className="d-flex flex-column">
                <span className="header">CONTACT US</span>
                <span><img alt="Pictogram showing paper folded plane." src={require("../images/pictograms/email.png")}/><a className="white-link" href={"mailto:" + mainEmail}>{ mainEmail }</a></span>
                <span><img alt="Pictogram showing an oldschool phone reciever." src={require("../images/pictograms/phone.png")}/><a className="white-link" href={"tel:" + phone}>{ phone }</a></span>
                <span><img alt="Pictogram showing a box full of lost objects." src={require("../images/pictograms/lost_and_found_pictogram.png")}/><a className="white-link" href={"mailto:" + lostAndFoundEmail}>{ lostAndFoundEmail }</a></span>
            </div>
            <div className="d-flex flex-column middle">
                <span className="header">Legal</span>          
                <a href="https://www.simber.co.uk/terms/general">General Terms & Conditions</a>
                <a href="https://www.simber.co.uk/terms/service">Service Conditions</a>
                <a href="https://www.simber.co.uk/terms/policy">Privacy Policy</a>
            </div>
            <div className="follow-section">
                <span className="header">FOLLOW US</span>
                <span className="social">
                    <img
                        onClick={redirectToFb}
                        alt="Pictogram of Facebook."
                        src={require("../images/pictograms/fb.png")}
                    ></img>                    
                    <img
                        onClick={redirectToInsta}
                        alt="Pictogram of Instagram."
                        src={require("../images/pictograms/insta.png")}
                    ></img>
                </span>
                
            </div>
            {isServicePage ||
                <img onClick={() => onTopClick()} className="top-arrow" src={require("../images/pictograms/top_arrow.png")} />
            }
        </footer>
    )
}

export default FooterComponent;