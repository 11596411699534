import React, { useEffect } from 'react';
import './BlogPage.scss'
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import MetaTags from 'react-meta-tags';
import { Container, Pagination, PaginationItem } from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import BlogPost from '../dtos/BlogPost';
import { Tastemonial } from '../dtos/Tastemonial';
import { blogPosts, featuredPosts, tags, tastemonials } from '../config/texts';
import { useState } from 'react';
import { Redirect, useLocation } from 'react-router';
import StructuredBreadcrumb, { IBreadcrumb } from './BreadCrumbs';
import { Link } from 'react-router-dom';
import usePagination from './Pagination';

export default function BlogPage() {

    const [detailsRedirect, setDetailsRedirect] = useState("");
    const [servicesRedirect, setServicesRedirect] = useState("");
    const [filteredBlogPosts, setFilteredBlogPosts] = useState(blogPosts);

    const PER_PAGE = 10;
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const page = parseInt(query.get('page') || '1', 10);
    const count = Math.ceil(filteredBlogPosts.length / PER_PAGE);

    const _DATA = usePagination(filteredBlogPosts, PER_PAGE);

    const handleChange = (e:any, p:number) => {
        _DATA.jump(p);
    };

    useEffect(() => {
        handleChange(null, page);
    }, []);

    // const responsive = {
    //     all: {
    //       breakpoint: { max: 3000, min: 0 },
    //       items: 1
    //     }
    // };

    function getRandom(arr: Array<any>, n: number) {
        var result = new Array(n),
            len = arr.length,
            taken = new Array(len);
        if (n > len)
            throw new RangeError("getRandom: more elements taken than available");
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }

    function filterBlogposts(text: string) {
        handleChange(null, 1);
        window.history.pushState({},"",`/blog`)
        setFilteredBlogPosts([...blogPosts].filter(e => e.details.includes(text) || e.titleFull.includes(text) || e.synopsis.includes(text)));
    }

    const randTestemonials: Tastemonial[] = getRandom(tastemonials,2);

    const breadcrumbList: IBreadcrumb[] = [
        {
          description: "Blog",
          url: "https://www.simber.co.uk/blog"
        },
    ]

    if(detailsRedirect != "") {
        window.scroll({top:0})
        return( <Redirect push to={"/blog/" + detailsRedirect} />)
    }
    else
    if(servicesRedirect != "") {
        window.scroll({top: 0});
        return( <Redirect push to={"/services/" + servicesRedirect} />)
    }
    return (
        <div className="service-background-wrapper blog">
            <MetaTags>
                <meta name="description" content="Simber Chauffeur Services Blog" />
                <title>Blog | Simber</title>
                <link rel="canonical" href={window.location.href} />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent />
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <div className="container">
                        <h1>Simber Blog Posts</h1>
                        <img onClick={() => window.scrollTo({top: 0, behavior: "smooth"})} className="top-img" src={require("../images/pictograms/top_arrow.png")}/>
                        <div className="blog-wrapper service-picture"></div>
                        <div className="content">
                            {/* <Carousel
                                className="carousel"
                                responsive={responsive}
                                arrows={false}
                                showDots={true}
                            >
                                {filteredBlogPosts.map(x =>{
                                    return (
                                        <div key={x.title} className="featured-posts-top">
                                            <div className="data">
                                                <span>{x.author}</span>
                                                <span className="dot"></span>
                                                <span>{x.date.getDate() + "." + (x.date.getMonth() + 1) + "." + x.date.getFullYear()}</span>
                                            </div>
                                            <h2 onClick={() => setDetailsRedirect(x.title.split(" ").join("-"))}>{x.titleFull}</h2>
                                            <p>{x.synopsis}</p>
                                            <div className="more-button" onClick={() => setDetailsRedirect(x.title.split(" ").join("-"))}>read more</div>
                                        </div>
                                    )
                                })}
                            </Carousel> */}
                            <div className="main-section">
                                <div className="posts">
                                    <div className='pagination-wrapper'>
                                        <Pagination
                                            page={page}
                                            count={count}
                                            size='large'
                                            onChange={handleChange}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    component={Link}
                                                    to={`/blog${item.page === 1 ? '' : `?page=${item.page}`}`}
                                                    {...item}
                                                />
                                            )}
                                        />
                                    </div>
                                    {filteredBlogPosts.length == 0 && <p className='no-results-text'>No results! Please update the search term.</p>}
                                    {_DATA.currentData().map((x: BlogPost) =>{
                                        return (
                                            <div key={x.slug} className="post-card">
                                                <img onClick={() => setDetailsRedirect(x.slug.split(" ").join("-"))} src={require("../images/blogposts/" + x.slug.split(" ").join("-") + "0.webp")} />
                                                <div className="data">
                                                    <div>
                                                        <img src={require("../images/pictograms/pen_pictogram.png")}/>
                                                        <span>Posted by {x.author}</span>
                                                    </div>
                                                    <div>
                                                        <img src={require("../images/pictograms/calendar_pictogram.png")}/>
                                                        <span>{x.date.getDate() + "." + (x.date.getMonth() + 1) + "." + x.date.getFullYear()}</span>
                                                    </div>
                                                </div>
                                                <h2><a href={"https://www.simber.co.uk/blog/" + x.slug.split(" ").join("-")}>{x.titleFull}</a></h2>
                                                <p>{x.synopsis}</p>
                                                <div className="more-button" onClick={() => setDetailsRedirect(x.slug.split(" ").join("-"))}><a className="placeholder-link" href={"https://www.simber.co.uk/blog/" + x.slug.split(" ").join("-")}>read more</a></div>
                                            </div>
                                        )
                                    })}
                                    <div className='pagination-wrapper'>
                                        <Pagination
                                            page={page}
                                            count={count}
                                            size='large'
                                            onChange={handleChange}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    component={Link}
                                                    to={`/blog${item.page === 1 ? '' : `?page=${item.page}`}`}
                                                    {...item}
                                                />
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="sidebar">
                                    <div className="search-bar">
                                        <input id="searchInput" placeholder="Search Articles" />
                                        <div onClick={() => filterBlogposts((document.getElementById("searchInput") as HTMLInputElement)?.value ?? "")} className="blog-search-button"><img src={require("../images/pictograms/search_pictogram.png")}/></div>
                                    </div>
                                    <div>
                                        <h2>Testimonials</h2>
                                        {randTestemonials.map(t => {
                                            return(
                                                <div key={t.name} className="tastemonial-card open">
                                                    <span className="tastemonial-text">{t.text}</span>
                                                    <span className="tastemonial-name">{t.name}</span>
                                                    <img alt="Pictogram of stars, representing a rating of 5." src={require("../images/pictograms/stars.png")}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="featured-posts-sidebar">
                                        <h2>Featured posts</h2>
                                        {featuredPosts.map(x => {
                                            return (
                                                <div key={x.titleFull} className="featured-post-card" onClick={() => setDetailsRedirect(x.slug.split(" ").join("-"))}>
                                                    <img src={require("../images/blogposts/" + x.slug.split(" ").join("-") + "0.webp")} />
                                                    <div className="data">
                                                        <h3><a className="placeholder-link" href={"https://www.simber.co.uk/blog/" + x.slug.split(" ").join("-")}>{x.titleFull}</a></h3>
                                                        <div><img src={require("../images/pictograms/calendar_pictogram.png")}/><span>{x.date.getDate() + "." + (x.date.getMonth() + 1) + "." + x.date.getFullYear()}</span></div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="services-menu">
                                        <h2>Services</h2>
                                        <div onClick={() => setServicesRedirect("airport-transfers")}><span>Airport Transfers</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("long-term-chauffeur")}><span>Long Term Chauffeuring</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("long-distance-chauffeur")}><span>Long Distance Chauffeuring</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("corporate-chauffeur")}><span>Corporate Chauffeurs</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("events-chauffeurs")}><span>Event Chauffeuring</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("wedding-chauffeur")}><span>Weddings</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("private-tours")}><span>Private Tours</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("seaport-transfers")}><span>Cruise Port Transfers</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div className='hide'>
                                            <a className="link" href="https://www.simber.co.uk/services/airport-transfers" ><span className="menu-item-text">Airport Transfers</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/long-term-chauffeur" ><span className="menu-item-text">Long Term Chauffeuring</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/long-distance-chauffeur" ><span className="menu-item-text">Long Distance Chauffeuring</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/corporate-chauffeur" ><span className="menu-item-text">Corporate Chauffeur</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/events-chauffeurs" ><span className="menu-item-text">Event Chauffeuring</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/wedding-chauffeur" ><span className="menu-item-text">Weddings</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/private-tours" ><span className="menu-item-text">Private Tours</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/seaport-transfers" ><span className="menu-item-text">Cruise Port Transfers</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/strategic-partnerships-hotel-transport" ><span className="menu-item-text">Strategic Partnership - Hotels</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/luxury-minibus-hire" ><span className="menu-item-text">Luxury Minibus Hire</span></a>
                                        </div>
                                    </div>
                                    <div className="tags-wrapper">
                                        <h2>Post tags</h2>
                                        <div className="tags">
                                            {tags.map((x,i) => {
                                                return (
                                                    <div className={i !== (tags.length-1) ? "tag margin" : "tag"}>{x}</div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <FooterComponent />
            </div>
        </div>
    )
}