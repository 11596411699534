import React, { useEffect, useState } from 'react';
import './DatePickerComponent.scss';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { ClickAwayListener, Slider } from '@material-ui/core';
import{ getMinDate } from "../redux/reducers/summaryReducer"

export interface IDatePickerProps { 
    valueChanged: (val: Date) => void
}

export default function DatePickerComponent(props: IDatePickerProps) {
    const [date, setDate] = useState(getMinDate())
    const [time, setTime] = useState(new Date(getMinDate().setMinutes(getMinDate().getMinutes() + 5)))
    const [open, setOpen] = useState(false);
    const [dateSection, setDateSection] = useState(true);
    const [timeout, setTimeOutVar] = useState<NodeJS.Timeout | undefined>(undefined);

    const setHoursManually = (e: EventTarget & HTMLInputElement) => {
        var v = e.value;
        if(v.startsWith("0") && v.length > 1){
            v = v.slice(1);
        }
        setTime(new Date(time.setHours(+v)));
        e.value = v;
    }

    const setMinutesManually = (e: EventTarget & HTMLInputElement) => {
        var v = e.value;
        if(v === "") {
            v = "00";
        }
        if(+v < 10){
            v = ("0" + v)
        }
        if(v.startsWith("00") && v.length > 2){
            v = v.slice(2);
        }
        if(v.startsWith("0") && v.length > 2){
            v = v.slice(1);
        }
        setTime(new Date(time.setMinutes(+v)));
        e.value = v;
    }

    useEffect(() => {
        propagateChange()
    },[date,time])

    const dateString = () => {
        var hours = time.getHours();
        var minutes = time.getMinutes();
        var minutesStr = minutes < 10 ? ('0' + minutes) : minutes;
        var strTime = 
            date.getDate() + "/" +
            (date.getMonth() + 1) + "/" +
            date.getFullYear() + ", " + 
            hours + ':' + minutesStr;
        return strTime;
    }

    const propagateChange = () => {
        var retDateHours = new Date(date.setHours(time.getHours()));
        var retDate = new Date(retDateHours.setMinutes(time.getMinutes()));
        props.valueChanged(retDate);
    }

    return (
        <div className="date-picker-wrapper">
            <div className={open ? "date-picker-input open" : "date-picker-input"} onClick={() => {
                    if(timeout) {
                        clearTimeout(timeout);
                        setTimeOutVar(undefined);
                    }
                    setTimeOutVar(setTimeout(() => setOpen(!open), 10));
                }}
            >
                <span>{dateString()}</span>
                <img className={open ? "rotate" : ""} src={require("../images/pictograms/arrow_grey.png")} />
            </div>
            { open && 
                <ClickAwayListener
                    onClickAway={() => {
                        if(timeout) {
                            clearTimeout(timeout);
                            setTimeOutVar(undefined);
                        }
                        setTimeOutVar(setTimeout(() => setOpen(!open), 10));
                    }}
                >
                    <div className="date-picker-accordion">
                        <div className="button-section">
                            <input type="button" value="Date" className={dateSection ? "active" : ""} onClick={() => setDateSection(true)}/>
                            <input type="button" value="Time" className={!dateSection ? "active" : ""} onClick={() => setDateSection(false)}/>
                        </div>
                        <div className="content-section">
                            { dateSection &&
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <DatePicker
                                    disableToolbar
                                    minDate={new Date()}
                                    variant="static"
                                    label="Only calendar"
                                    helperText="No year selection"
                                    value={date}
                                    onChange={(val) => {setDate(val as Date);}}
                                    />
                                </MuiPickersUtilsProvider>
                            }
                            {
                                dateSection || 
                                    <div className="time-picker">
                                        <div className="time-display">
                                            <input className="time-display-input" type="number" value={time.getHours()} onChange={(e) => setHoursManually(e.currentTarget)} />
                                            <img alt="Double dots between hours and minutes" src={require("../images/pictograms/time_dots.png")}/>
                                            <input className="time-display-input" type="number" value={time.getMinutes()} onChange={(e) => setMinutesManually(e.currentTarget)} />
                                        </div>
                                        <div className="time-slider-wrapper">
                                            <span>Hours</span>
                                            <Slider
                                                value={time.getHours()}
                                                min={0}
                                                max={24}
                                                onChange={(e,v) => {setTime(new Date(time.setHours(v as number)));}}
                                                onChangeCommitted={() => propagateChange()}
                                            />
                                        </div>
                                        <div className="time-slider-wrapper">
                                            <span>Minutes</span>
                                            <Slider
                                                value={time.getMinutes()}
                                                min={0}
                                                max={60}
                                                onChange={(e,v) => {setTime(new Date(time.setMinutes(v as number)));}}
                                                onChangeCommitted={() => propagateChange()}
                                            />
                                        </div>
                                        <input
                                            className="done-button"
                                            onClick={() =>{
                                                if(timeout) {
                                                    clearTimeout(timeout);
                                                    setTimeOutVar(undefined);
                                                }
                                                setTimeOutVar(setTimeout(() => setOpen(!open), 10));}} type="button" value="Done" 
                                        />
                                    </div>
                            }
                        </div>
                    </div>
                </ClickAwayListener>
            }
        </div>
    )
}