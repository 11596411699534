import { SAVE_STATE, CLEAR_STATE } from "../actionTypes";
import { CarType, RentType } from "../../dtos/PriceData";
import { Preference } from "../../dtos/PaymentData";
import { preferencesList } from "../../config/Preferences";
import { SummaryActionTypes } from "../actions";

export interface SummaryState{
  date: Date;
  selectedType?: CarType;
  email: string;
  name: string;
  origin: string;
  destination: string;
  rentType: RentType;
  time: number;
  price?: number;
  preferences: Preference[];
  phone: string;
}

function getPreferences(): Preference[] {  
  if(localStorage.getItem("pref0") === null || localStorage.getItem("pref0") === undefined){
    var newArray: Preference[] = [];
    preferencesList.forEach(element => {
      var x:Preference = {Id: element.Id, Text: element.Text, Value: element.Value}
      newArray.push(x);
    });
    return newArray;
  }
  var list: Preference[] = [];
  var x = preferencesList.length;
  for(let i = 0; i < x; i++){
    list.push({ Value: localStorage.getItem("pref"+i) === "1", Text: preferencesList[i].Text, Id: preferencesList[i].Id });
  }
  return list;
}

function getRentType(): RentType {
  switch(localStorage.getItem("rentType")){
    case "0":
      return RentType.Oneway;
    case "1":
      return RentType.ByTheHour;
    default:
      return RentType.Oneway;
  }
}

function getSelectedType(): CarType | undefined {
  switch(localStorage.getItem("selectedType")){
    case "1":
      return CarType.Premium;
    case "2":
      return CarType.SevenSeater;
    case "3":
        return CarType.Luxury;
    default:
      return undefined;
  }
}

export function getMinDate(): Date {
  var now = new Date();
  var min = now.setTime(now.getTime() + (12*60*60*1000));
  return new Date(min);
}

const clearState = {
   date: new Date(),
   destination: "",
   email:  localStorage.getItem("email") ?? "",
   name:  localStorage.getItem("email") ?? "",   
   origin: "",
   phone: "",
   preferences: [],
   rentType: getRentType(),
   price: 0,
   selectedType: undefined,
   time: 0
}

var initialState: SummaryState = {
   date: localStorage.getItem("date") !== null ? new Date(localStorage.getItem("date") ?? "") : new Date(getMinDate().setMinutes(getMinDate().getMinutes()+10)),
   destination: localStorage.getItem("destination") ?? "",
   email: localStorage.getItem("email") ?? "",
   name: localStorage.getItem("name") ?? "",   
   origin: localStorage.getItem("origin") ?? "",
   phone: localStorage.getItem("phone") ?? "",
   preferences: getPreferences(),
   rentType: getRentType(),
   price: Number.parseInt(localStorage.getItem("price") as string),
   selectedType: getSelectedType(),
   time: Number.parseInt(localStorage.getItem("time") as string)
  };
  
export default function(state = initialState, action: SummaryActionTypes): SummaryState {
  switch (action.type) {
    case SAVE_STATE: {      
      if(action.payload !== undefined) {
        localStorage.setItem("date", action.payload.date.toString());
        localStorage.setItem("destination", action.payload.destination);
        if(action.payload.email !== "")
          localStorage.setItem("email", action.payload.email);
        if(action.payload.name !== "")
          localStorage.setItem("name", action.payload.name);
        localStorage.setItem("origin", action.payload.origin);
        if(action.payload.phone !== "")
          localStorage.setItem("phone", action.payload.phone);
        var i = 0;
        action.payload.preferences.forEach(pref => {
          localStorage.setItem("pref" + i++, pref.Value ? "1" : "0");
        })
        localStorage.setItem("rentType", action.payload.rentType.toString());
        localStorage.setItem("price", action.payload.price?.toString() ?? "");
        localStorage.setItem("selectedType", action.payload.selectedType?.toString() ?? "");
        localStorage.setItem("time", action.payload.time.toString());
        return {
          ...state,
          date: action.payload.date,
          destination: action.payload.destination,
          email: action.payload.email,
          name: action.payload.name,
          origin: action.payload.origin,
          preferences: action.payload.preferences,
          rentType: action.payload.rentType,
          price: action.payload.price,
          selectedType: action.payload.selectedType,
          time: action.payload.time,
          phone: action.payload.phone
        }
      }
      else{
        return{
          ...state
        }
      }
    }
    case CLEAR_STATE: {
      var prefs:Preference[] = [];
      preferencesList.forEach(element => {
        var x:Preference = {Id: element.Id, Text: element.Text, Value: element.Value}
        prefs.push(x);
      });
      return {
        ...clearState,
        preferences: prefs
      }
    }
    default:
      return state;
  }
}
  