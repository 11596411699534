import React, { useEffect, useState } from 'react';
import './GalleryPage.scss';
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import Carousel from 'react-multi-carousel';
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from './BreadCrumbs';

export default function GalleryPage() {
    const [init, setInit] = useState(true);
    const [beforeRender, setBeforeRender] = useState(true);
    const [leftImage, setLeftImage] = useState(1);
    const [currentImage, setCurrentImage] = useState(2);
    const [rightImage, setRightImage] = useState(3);
    var carousel: Carousel | undefined = undefined;

    const maxImages = 17;

    const responsive = {
        desktop: {
          breakpoint: { max: 4000, min: 460 },
          items: 3
            
        },
        mobile: {
          breakpoint: { max: 460, min: 0 },
          items: 1
        }
    };

    const getImageList = () => {
        var images = [];
        for(let i = 1; i <= maxImages; i++){
            images.push(<img className={"image"} id={"img-" + i} src={require('../images/gallery/gallery-' + i + '.png')} />)
        }
        return images
    }
    //before render
    useEffect(() => {
        if(beforeRender) {
            setBeforeRender(false);
        }
    },[beforeRender])

    useEffect(() => {
        if(!beforeRender && init) {
            setInit(false)
            window.scroll({top: 0});
            onSmallImgClick(2)
        }
        let i: number;
        var imageDiv = document.getElementById("sm-images") as HTMLElement;
        var width = imageDiv?.offsetWidth ?? 0;
        imageDiv.innerHTML = '';
        if(width >= 460) {
            var max = Math.floor((width/138));
            if(max > maxImages)
                max = maxImages;
            if(max % 2 === 0)
                max = max-1;            
            var min = Math.floor(max/2);
            
            for(i = currentImage - min; i <= currentImage + min; i++){
                var img = document.createElement("img");  
                let imgNumber = i;
                if(imgNumber  < 1) {
                    imgNumber = maxImages + imgNumber;
                }
                if(imgNumber > maxImages) {
                    imgNumber = imgNumber - maxImages;
                }
                
                img.addEventListener("click",() => onSmallImgClick(imgNumber));
                img.src = require("../images/gallery/gallery-" + imgNumber + ".png");
                imageDiv.appendChild(img);
            }
        }
        else {
            var img = document.createElement("img");            
            let imgNumber = currentImage - 1;
            if(imgNumber  < 1) {
                imgNumber = maxImages + imgNumber;
            }
            if(imgNumber > maxImages) {
                imgNumber = imgNumber - maxImages;
            }
            img.addEventListener("click",() => onSmallImgClick(imgNumber));
            img.src = require("../images/gallery/gallery-" + imgNumber + ".png");
            imageDiv.appendChild(img);
            
            var img2 = document.createElement("img");            
            let imgNumber2 = currentImage + 1;
            if(imgNumber2  < 1) {
                imgNumber2 = maxImages + imgNumber2;
            }
            if(imgNumber2 > maxImages) {
                imgNumber2 = imgNumber2 - maxImages;
            }
            img2.addEventListener("click",() => onSmallImgClick(imgNumber2));
            img2.src = require("../images/gallery/gallery-" + imgNumber2 + ".png");
            imageDiv.appendChild(img2);
        }
    })

    const onArrowRight = () => {
        if(currentImage === maxImages-1) {
            setLeftImage(maxImages-1);
            setCurrentImage(maxImages);
            setRightImage(1);
        }
        else
        if(currentImage === maxImages) {
            setLeftImage(maxImages);
            setCurrentImage(1);
            setRightImage(2);
        }
        else
        if(currentImage === 1) {
            setLeftImage(1);
            setCurrentImage(2);
            setRightImage(3);
        }
        else {            
            setLeftImage(leftImage+1);
            setCurrentImage(currentImage+1);
            setRightImage(rightImage+1);
        }
    }

    const onArrowLeft = () => {
        if(currentImage === 1) {
            setLeftImage(maxImages-1);
            setCurrentImage(maxImages);
            setRightImage(1);
        }
        else
        if(currentImage === maxImages) {
            setLeftImage(maxImages-2);
            setCurrentImage(maxImages-1);
            setRightImage(maxImages);
        }
        else
        if(currentImage === 2) {
            setLeftImage(maxImages);
            setCurrentImage(1);
            setRightImage(2);
        }
        else {            
            setLeftImage(leftImage-1);
            setCurrentImage(currentImage-1);
            setRightImage(rightImage-1);
        }
    }

    const onSmallImgClick = (num: number) => {
        setCurrentImage(num);
        var imageDiv = document.getElementById("sm-images") as HTMLElement;
        var width = imageDiv?.offsetWidth ?? 0;
        if(width >= 460)
            carousel?.goToSlide(num + 4,{skipAfterChange: false, skipBeforeChange: false})
        else
            carousel?.goToSlide(num + 4,{skipAfterChange: false, skipBeforeChange: false})
        
        if(num === 1) {
            setLeftImage(maxImages);
            setRightImage(num+1);
        }
        else
        if(num === maxImages) {
            setLeftImage(num - 1)
            setRightImage(1);
        }
        else {
            setLeftImage(num - 1);
            setRightImage(num + 1);
        }
    }
    
    const breadcrumbList: IBreadcrumb[] = [
        {
          description: "Gallery",
          url: "https://www.simber.co.uk/gallery"
        },
    ]

    return(
        <div className="wrapper">
            <MetaTags>
                <meta name="description" content="Simber Chauffeur Services Gallery" />
                <title>Gallery | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/gallery" />
            </MetaTags>
            <HeaderComponent/>
            <main>
                <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                <Carousel
                    ref={(el) => { var x = (el as Carousel); if(x)carousel = x; }}
                    className="carousel"
                    infinite={true}
                    centerMode={false}
                    responsive={responsive}
                    partialVisbile={false}
                    partialVisible={false}
                    afterChange={(prev, {currentSlide}) => {
                        var images = document.getElementsByClassName("react-multi-carousel-item--active");

                        if(images.length !== 1) {
                            images[1].classList.add("front");
                            images[1].classList.remove("back");
        
                            images[0].classList.add("back");
                            images[0].classList.remove("front");
                            images[2].classList.add("back");
                            images[2].classList.remove("front");

                            if(prev + 1 === currentSlide){
                                onArrowRight();
                            }
                            if(prev - 1 === currentSlide) {
                                onArrowLeft();
                            }
                        }
                        else {
                            images[0].classList.add("front");
                        }
                    }}
                    beforeChange={(next, {currentSlide}) => {
                        
                        var images = document.getElementsByClassName("react-multi-carousel-item--active");
                        
                        if(images.length !== 1) {
                            images[1].classList.remove("front");

                            if(window.outerWidth <= 800) {
                                if(next + 1 === currentSlide)
                                {
                                    images[0].classList.add("front");
                                    images[0].classList.remove("back");
                                }
                                else {
                                    images[2].classList.add("front");
                                    images[2].classList.remove("back");
                                }
                            }
                        }
                        else {
                            images[0].classList.remove("front");
                        }
                    }}
                >
                    {getImageList()}
                </Carousel> 
            </main>
            <div className="gallery-images">
                <div id="sm-images" className="small-images"></div>            
            </div>
            <FooterComponent/>
        </div>
    )
}