import React from 'react';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import { AccordionComponent } from './AccordionComponent';
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';
import MetaTags from 'react-meta-tags';
import { Container } from '@material-ui/core';
import './LuxuryMinibusHirePage.scss'

export default function LuxuryMinibusHirePage(){

    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
          description: "Luxury Minibus Hire in London",
          url: "https://www.simber.co.uk/services/luxury-minibus-hire"
        },
    ]
    
    return(
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="A luxury minibus hire is the perfect combination for your trip in London so you can experience the city's magic with your family or friends. Contact Simber now." />
                <title>Looking for Luxury Minibus Hire in London | Simber Chauffeur Services</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/luxury-minibus-hire" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="md" className="container">
                        <img onClick={() => window.scrollTo({top: 0, behavior: "smooth"})} className="top-img" src={require("../../images/pictograms/top_arrow.png")}/>
                        <div className="minibus-wrapper service-picture"></div>
                        <div className="content">
                            <h1>Luxury Minibus Hire in London</h1>
                            <div className='offset-text-before-title'>
                                <p>Luxury minibuses are the most convenient choice for group trips. No matter if you travel with your friends, family, or colleagues, an exclusive minibus can surely enhance your London experience.</p>
                                <p>But what does it take to hire a chauffeured minibus? Let's get there:</p>
                            </div>
                            <h2>Hire a Minibus from Simber for Your Ultimate Comfort While in London</h2>
                            <p>If you need a minibus hire, you have come to the right place. Our minibus chauffeur service will provide you with comfort, luxury, and safety on the road to your desired destination. Whether travelling around London, or the rest of the United Kingdom, you can relax since you are in the hands of a licensed professional chauffeur.</p>
                            <p><a href="https://www.simber.co.uk/contact">Contact Us.</a></p>
                            <h3>Private Tour in London</h3>                 
                            <p>To hire a chauffeur-driven minibus means you're ready to discover all the beauties the UK capital has to offer. You will have the chance to travel together as a team with your family and friends. Furthermore, a minibus and driver hire is the perfect combination to visit all the exhibition halls, green parks, museums, and many other tourist attractions. Also, once you hire minibus, you can get a professional chauffeur who will answer your questions on local sites and give you local tips about places you can’t easily find online.</p>
                            <div className="devider"></div>
                            <h3>Visit Sports and Corporate Events</h3>
                            <p>When hiring a minibus with a driver in London, you can expect a stress-free journey while preparing for your favourite sports event.</p>
                            <p>Travel together as a group no matter if you want to take part in Horse Racing at Royal Ascot in Windsor, Formula One at Silverstone Circuit, Tennis at Wimbledon, London Fashion Week, or travel to live shows in premium luxury.</p>
                            <p>And the same goes for corporate events of any kind. Whether you need a minibus hire London for you and your colleagues, or you are organising transport for guests, an enterprise minibus hire is the perfect solution.</p>
                            <div className="devider"></div>
                            <h3>Airport Transfers with VIP Minibus</h3>
                            <p>Simber’s minibus rentals are also excellent for airport transfers since we can pick you up at any airport. Whether you arrive with your family, friends, or any other group of people, we can arrange you an executive minibus hire that will wait for you right when you land, and it will take you to your booked accommodation or any other place you want.</p>
                            <p>Also, the same rules apply if you need to get to any airport on time. An affordable minibus hire with driver is the perfect combination for not just not being late, but it’s also a great option since it’s not advisable for you yourself to drive in such an enormous city like London, especially when you are in a rush!</p>
                            <div className="devider"></div>
                            <h3>Your Wedding Day</h3>
                            <p>All the different feelings, preparations, and various other challenges on your wedding day will instantly motivate you to search for a minibus hire near me. And that’s okay!</p>
                            <p>Why?</p>
                            <p>Because you and your loved one already have too many things to take care of, so why bother about transportation when you can use a luxury minibus with a professional chauffeur for your family and friends who will be able to enjoy the ride. With a wedding minibus hire you along with your family and friends will experience many memorable moments while riding, furthermore it’s suitable for guests coming from other cities or countries as well. You can arrange a London minibus hire both for you and your guests for your special day! Don’t hesitate to reach out for a tailor made quote.</p>
                            <p>Find out more about how to partner with us <a href="https://www.simber.co.uk/contact">here</a>.</p>
                            <div className="devider"></div>
                            <h3>Hire a Minibus Chauffeur for a Bachelor/Bachelorette Party</h3>
                            <p>In order to have a fantastic bachelor or bachelorette party, you have to get together with your friends in a perfectly planned schedule. And what better way to get together than in a 16 seater minibus hire riding to your desired destination.</p>
                            <p>You will be excited, you will have a lot of fun, and it is certainly best to hire a professional chauffeur to avoid driving yourself. This party should be one of the best of your life, so relax and leave the driving part to our professionals.</p>
                            <p>Of course, if you need to hire a minibus with driver for any other occasion, Simber is here to provide you with the best chauffeur services! </p>
                            <div className="devider"></div>
                            <h2>Vip Minibus Hire Benefits</h2>
                            <p className='italic'>VIP minibuses offer a wide array of benefits to elevate your overall travel experience with the highest level of comfort and convenience. Minibuses, as expected, provide space, plush seating, and luxurious setting for group travels.</p>
                            <p className='italic'>At the same time, expert chauffeurs guarantee a smooth ride, allowing every passenger to relax. No matter if you travel for corporate events, special occasions, or family activities, you can enjoy in:</p>
                            <ul>
                                <li>Cost Savings</li>
                                <li>Ease</li>
                                <li>Luxury Travel</li>
                                <li>Seeing The Country</li>
                                <li>Keeping Stress At Bay</li>
                                <li>Safety</li>
                                <li>Comfort</li>
                            </ul>
                            <div className="devider"></div>
                            <h2>Luxury Minivan vs Luxury Minibus: What's the Difference?</h2>
                            <p>While minivans and minibuses seem similar, it's important to note that they're quite different.</p>
                            <p>In general, remember that a minivan's interior is more similar to a car with more cargo space, while a minibus can carry more passengers, typically more than 12 at a time.</p>
                            <p>Luxury minivans are often referred to as bigger and more comfortable family vehicles, while minibuses are great for commercial transport.</p>
                            <p>Finding the best luxury minivan depends on your specific needs and travel requirements. Still, when compared to minibuses, we can say that the difference can be narrowed down to:</p>
                            <ul>
                                <li>Not so spacious luxury minivan interior</li>
                                <li>It’s similar to car, not a minibus</li>
                                <li>It carries fewer passengers compared to the chauffeur minibuses</li>
                            </ul>
                            <div className="devider"></div>
                            <h2 className="faq-section-header">LIST OF FAQ</h2>
                            <div className="faq-wrapper" itemScope itemType="https://schema.org/FAQPage">
                                <AccordionComponent
                                    question="Where can I hire a minibus?" 
                                    answer="You can hire a minibus travel through our Simber services. We can provide you a trained and professional chauffeur from and to any place in London and the surrounding areas. Comfort, safety, and luxury are only some of the many reasons we are the best possible choice."
                                />
                                <AccordionComponent
                                    question="What licence do I need to drive a minibus for hire and reward?" 
                                    answer="One can drive a minibus for hire with a minibus driving test with a category D1 licence, or a bus driving test and a category D licence. But considering the high-quality of our professional chauffeur service in London, you will be in safe hands with our chauffeurs since their number one priority is to keep you safe and provide you with a comfortable ride."
                                />
                                <AccordionComponent
                                    question="How much to hire a minibus?" 
                                    answer="It is more than worth it to hire a Simber minibus considering the professional services you will get for your money. Our minibus hire prices are dependent on locations and starting points, so it is always best to get in touch for a tailor made quote.  The minibus hire cost includes complete professionalism, luxurious ride, safe transportation, and high-level punctuality. "
                                />
                                <AccordionComponent
                                    question="Will the chauffeur wait for me at my location?" 
                                    answer="Yes, our chauffeur will wait for you at your desired location, no matter if it’s a London airport, your hotel, etc. A minibus rental with driver is the perfect solution for you and the group of people you are travelling with since you will always be on time, or be able to leave your belongings in the vehicle while you are sightseeing. Remember your chauffeur is on standby, or as directed by you, feel free to let them know what you’d like to do! "
                                />
                                <AccordionComponent
                                    question="Will the chauffeur respect my confidentiality and privacy?" 
                                    answer="The chauffeur will respect your privacy at all times. Simber chauffeurs are also able to sign a non-disclosure agreement, so any conversation in the vehicle will stay in the vehicle. Either way, you don’t have to worry, our strong beliefs on privacy and discretion are our top priority."
                                />
                                <AccordionComponent
                                    question="Do you provide car seats for children?" 
                                    answer="We provide various types of child seats for children such as booster seats, child seats, and baby seats. Just please add your child’s age or the type of child seat you need before you confirm your booking."
                                />
                                <AccordionComponent
                                    question="Can I book the same driver for other Simber services?" 
                                    answer="Of course, if you are satisfied with the service received, you can request the same professional chauffeur for any other service, as long as the chauffeur is available and not requested by another client. "
                                />
                                <AccordionComponent
                                    question="What's the difference between a minibus and a minivan?" 
                                    answer="Minibus vs. Minivan: Minibuses are larger, like smaller buses with more than 12 individual seats. On the other hand, minivans can accommodate up to 8 passengers on the bench seats."
                                />
                                <AccordionComponent
                                    question="What's the difference between a minibus and a microbus?" 
                                    answer="Minibus vs. Microbus: The difference between these two is the commercial use. While minibuses are more for commercial use, group travel, and organized tours, microbuses are used more for personal purposes."
                                />
                                <AccordionComponent
                                    question="How to spot the best chauffeur-driven minibus hire service?" 
                                    answer="It's simple, like reading and comparing reviews and testimonials to narrow down your choice. Simber can be the answer to your question as we offer convenient minibus hire with a driver in London for every client."
                                />
                            </div>
                        </div>
                    </Container>
                </main>
                <FooterComponent/>
            </div>
        </div>
    )
}