import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Snackbar } from '@material-ui/core';
import React, { useState } from 'react';
import './Settings.scss';
import PasswordInput from '../PasswordInput';
import { axiosInstance } from '../../config/Axios.config';
import Alert from '@material-ui/lab/Alert';
import ChangePasswordDto from '../../dtos/ChangePasswordDto';
import DeleteIcon from '@material-ui/icons/Delete';
import { bindActionCreators, Dispatch } from 'redux';
import { clearUserState } from "../../redux/actions"
import { connect, useDispatch } from 'react-redux';
import { UserActionTypes } from '../../redux/actions';
import { RootState } from '../../redux/reducers/rootReducer';
import { Redirect } from 'react-router';

interface IDispatchedProps {
    clearUserState: () => void;
}

interface IMappedProps {
    token: string;
}

type Props = IMappedProps & IDispatchedProps

function Settings(props: Props) {
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [updated, setUpdated] = useState(false);
    const [error, setError] = useState("");
    const [openConfirm, setOpenConfirm] = useState(false);
    const [redirect, setRedirect] = useState(false);

    const dispatch = useDispatch();

    const tryChangePassword = () => {

        if(newPassword !== newPasswordConfirm) {
            setError("Password and confirmation does not match");
            return;
        }
        axiosInstance.defaults.headers["Authorization"] = "Bearer " + localStorage.getItem("token");

        var data: ChangePasswordDto = {
            password: password,
            newPassword: newPassword,
            newPasswordConfirm: newPasswordConfirm
        }
        axiosInstance.post("profile/changePass", data)
            .then(res => {
                setUpdated(true);
            })
            .catch(err => {
                if (err.response?.status === 404) {
                    setError("Unknown error occured");
                }
                else {
                    if(err.response?.status === 419) {
                        localStorage.clear();
                        window.location.href = "/";
                        return;
                      }
                    if (err.response?.data !== undefined) {
                    setError(err.response.data.message);
                    }
                    else {
                    setError("Cannot reach server");
                    }
                }
            })
        
    }

    const tryDeleteAccount = () => {
        axiosInstance.delete("user")
            .then(x => {
                dispatch(props.clearUserState());
                localStorage.clear();
                setRedirect(true);
            })
            .catch(err => {
                if(err.response?.status === 419) {
                    localStorage.clear();
                    window.location.href = "/";
                    return;
                }
            })
    }

    if(redirect){
        return (<Redirect push to="/home" />)
    }
    else
    return (
        <div className="settings-wrapper">
            {updated ||
                <div className="content-wrapper">
                    <div>
                        <span className="input-label">Confirm current password:</span>
                        <PasswordInput
                            placeholder="Current password"
                            value={password}
                            setValue={setPassword}
                        />            
                        <span className="input-label">Change current password:</span>
                        <PasswordInput
                            placeholder="New password"
                            value={newPassword}
                            setValue={setNewPassword}
                        />
                        <PasswordInput
                            placeholder="Confirm password"
                            value={newPasswordConfirm}
                            setValue={setNewPasswordConfirm}
                        />
                        <Button className="profile-primary-button" onClick={() => tryChangePassword()}>Confirm</Button>
                    </div>
                    <div className="delete-wrapper">
                        <Button 
                        className="delete-button"
                        variant="contained"
                        startIcon={<DeleteIcon />}
                        onClick={() => setOpenConfirm(true)}>
                        Delete Account
                        </Button>
                        <Dialog
                            maxWidth="sm"
                            className="confirm-dialog"
                            open={openConfirm}
                            onClose={() => setOpenConfirm(false)}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Confirm account deletion.</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure? this cannot be undone!
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={() => setOpenConfirm(false)}
                                    className="cancel-button"
                                 >
                                    Cancel
                                </Button>
                                <Button
                                    className="delete-button"
                                    onClick={tryDeleteAccount}
                                    >
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </div>
                </div>
            }
            {updated &&
                <span className="updated-text">Your password has been updated.</span>
            }
            <Snackbar open={error !== ""}>
                <Alert onClose={() => setError("")} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </div>
    )
}

const mapStateToProps = (state: RootState): IMappedProps => {
    return{ token: state.user.token}
  };

const mapDispatchToProps = (dispatch: Dispatch<UserActionTypes>) =>
  bindActionCreators(
    {
      clearUserState
    },
    dispatch
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Settings)