import { Button, Container, CssBaseline, Snackbar, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import './ResetPassPage.scss'
import { axiosInstance } from '../config/Axios.config';
import Alert from '@material-ui/lab/Alert';
import { Redirect } from 'react-router';
import MetaTags from 'react-meta-tags';

interface TokenType {
    token: string;
}

interface ResetPassDto {
    email: string;
    newPassword: string;
    newPasswordConfirm: string;
}

export default function ResetPassPage() {

    const [newPass, setNewPass] = useState("");
    const [confirmPass, setConfirmPass] = useState("");
    const [error, setError] = useState("");
    const [email, setEmail] = useState("");
    const [resetSuccess, setResetSuccess] = useState(false);
    const [loginRedirect, setLoginRedirect] = useState(false);

    const tryChangePass = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if(email === "") {
            setError("Email cannot be empty.");
            return;
        }
        if(newPass === "") {
            setError("New password cannot be empty.");
            return;
        }
        if(newPass !== confirmPass) {
            setError("New Password and confirmation does not match.");
            return;
        }
        var data: ResetPassDto = {
            email: email,
            newPassword: newPass,
            newPasswordConfirm: confirmPass
        }
        var query = window.location.search;
        axiosInstance.post("user/resetPass" + query, data) 
            .then(res =>{
                setResetSuccess(true);
            })
            .catch(err => {
                if (err.response?.status === 404) {
                    setError("Unknown error occured");
                }
                else {
                    if(err.response?.status === 419) {
                        localStorage.clear();
                        window.location.href = "/";
                        return;
                      }
                    if (err.response?.data !== undefined) {
                    setError(err.response.data.message);
                    }
                    else {
                    setError("Cannot reach server");
                    }
                }
            })
    }
    if(loginRedirect) {
        return (<Redirect push to="/login"/>)
    }
    else {
    return(
        <div className="wrapper">
            <MetaTags>
                <meta name="description" content="Simber Chauffeur Services reset password page" />
                <title>Reset Password | Simber</title>
            </MetaTags>
            <HeaderComponent />
            <main>
                <Container component="main" maxWidth="sm">
                <CssBaseline />
                {resetSuccess ||
                    <div className="login-card">
                        <Typography className="sign-in-header" component="h1" variant="h5">
                            New Password
                        </Typography>
                        <form noValidate>
                        <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            label="Confirm email"
                            autoFocus
                            value={email}
                            onChange={(e) => { setEmail(e.currentTarget.value) }}
                            />
                            <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            label="New password"
                            autoFocus
                            type="password"
                            value={newPass}
                            onChange={(e) => { setNewPass(e.currentTarget.value) }}
                            />
                            <TextField
                            variant="filled"
                            margin="normal"
                            required
                            fullWidth
                            label="Confirm password"
                            type="password"
                            value={confirmPass}
                            onChange={(e) => { setConfirmPass(e.currentTarget.value) }}
                            />
                            <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            onClick={(e) => tryChangePass(e)}
                            >
                            Change Password
                            </Button>
                        </form>
                    </div>
                }
                {resetSuccess &&
                    <div className="login-card">
                        <span className="success">Password Changed!</span>
                        <a onClick={() => setLoginRedirect(true)}>Sign In</a>
                    </div>
                }
                </Container>
            </main>
            <Snackbar
                open={error !== ""}
                onClose={() => setError("")}
                >
                <Alert onClose={() => setError("")} severity="error">
                    {error}
                </Alert>
            </Snackbar>
            <FooterComponent />
        </div>
    )
    }
}