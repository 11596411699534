import { Container } from '@material-ui/core';
import React from 'react';
import './CovidPage.scss';
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from './BreadCrumbs';

export default function CovidPage() {

    const breadcrumbList: IBreadcrumb[] = [
        {
          description: "Covid 19",
          url: "https://www.simber.co.uk/covid19"
        },
    ]
    
    return (
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="Learn how Simber deals with the pandemic in order to ensure safe and healthy transport for you and your family with our services. Your safety is our priority!" />
                <title>Covid19 Precautions and Information | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/covid19" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="md" className="container">
                        <div className="covid-wrapper service-picture"></div>
                        <div className="content">
                            <h1 className="details-header">Covid19 Precautions & Information</h1>
                            <p>At Simber, it is of utmost importance that the health and safety of everyone included in our transport services, from the team members to passengers everyone is our priority. For that purpose, we designed a protocol that guarantees the safety to travel with us. Here is how we protect you and ourselves while  being able to provide you with superior chauffeur driven transport services.</p>
                            <ol className="covid-ol">
                                <li>Contactless Travel – Your chauffeur will make sure that you enter and exit the vehicle without any contact.</li>
                                <li>Disinfected vehicle before and after every journey</li>
                                <li>Daily temperature tested chauffeurs. </li>
                            </ol>
                            <p>Our Covid19 safety tips that refer to both chauffeurs and passengers include:</p>
                            <ul className="ticked-list">
                                <li>Wash your hands before and after each trip </li>
                                <li>Wear a mask inside the vehicles at all times</li>
                                <li>No handshake greetings. Our chauffeur greets you with a slight bow instead of a handshake</li>
                                <li>Use the antibacterial gel, tissues or hand sanitizer in the vehicle</li>
                                <li>Sit in the back, on the left where there is the maximum distance from your chauffeur </li>
                                <li>Have the windows slightly opened to improve the airflow in the vehicle </li>
                                <li>Air condition set to extract, not circulate</li>
                                <li>Have your luggage sanitized by the chauffeur</li>
                            </ul>
                            <div className="picture-section big">
                                <img alt="Cleaning car in the covid pandemic." className="" src={require("../images/servicepages/covid_1.png")}/>
                                <div className="content">
                                    <p>Our advice, along with government advice is not to travel if you have any of the symptoms of Covid19. Therefore, if you experience any symptoms, before, after or on the day of the booked journey, please notify us so we can protect you and our team the best way possible. </p>
                                    <p>Our ultimate goal is to make every journey enjoyable, we strongly believe that during the global pandemic you will only do so if you are relaxed within your environment. Let our chauffeurs protect you and carry your family and colleagues safely in a clean, sanitized,  comfortable and healthy environment. </p>
                                    <p>If you have any additional Covid19-related questions, please talk to us using the contact form on our <a href="https://www.simber.co.uk/contact">Contact us</a> page.</p>
                                </div>
                        </div>
                        </div>
                    </Container>
                </main>
                <FooterComponent />
            </div>
        </div>
    )
}