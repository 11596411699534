import React, {  useState } from 'react';
import './AccordionComponent.scss';

interface Props {
    title: string;
    titleLevelH3?: boolean;
    img: string;
    text: string;
    oddRow: boolean;
    redirect: string;
    covid?: boolean;
    alt: string;
}

function AccordionComponent(props: Props) {

    const learnMore = () => {
        if(props.covid) {
            window.open(props.redirect,"_self");
        }
        else {
            window.open("/services" + props.redirect,"_self");
        }        
    }
    return (
        <div className="homepage-card-wrapper">
                {props.oddRow ||
                    <img src={require('../images/' + props.img)} alt={props.alt}/>                        
                }
                <div className={props.oddRow ? "homepage-card-content odd" : "homepage-card-content"}>
                    {props.titleLevelH3 ? <h3 className="title">{props.title}</h3> : <h2 className="title">{props.title}</h2>}
                    <div className="hompage-card-content-mid">
                        <div className="hompage-card-text">
                            <span>{props.text}</span>
                                <a className="primary-button" href={"https://www.simber.co.uk" + (props.covid ? "" : "/services") + props.redirect}>more</a>
                            {/* <button className="primary-button" onClick={learnMore}>more</button> */}
                        </div>
                        <span className="title-devider"></span>
                    </div>
                </div>
                {props.oddRow &&
                    <img src={require('../images/' + props.img)}/>
                }
        </div>
    );
}

export default AccordionComponent