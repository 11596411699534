import { Container } from '@material-ui/core';
import React from 'react';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import { AccordionComponent } from './AccordionComponent';
import './PrivateToursPage.scss';
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';

export default function PrivateTours(){

    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
          description: "Private Tours",
          url: "https://www.simber.co.uk/services/private-tours"
        },
    ]
    
    return(
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="Embark on an extraordinary journey through this captivating city with our Private London Tours - where luxury meets discovery." />
                <title>London Private Tours with Reliable Chauffeurs</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/private-tours" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="md" className="container">
                        <img onClick={() => window.scrollTo({top: 0, behavior: "smooth"})} className="top-img" src={require("../../images/pictograms/top_arrow.png")}/>
                        <div className="private-wrapper service-picture"></div>
                        <div className="content">
                            <h1>Bespoke Private London Tours with Chauffeur</h1>
                            <p>You want to visit London but aren't excited about crowds and busy streets? What if we tell you that you won't have to deal with such challenges if you let us organize your private tour and take you on an unforgettable adventure through our city?</p>
                            <p>Here's how we make private London tours happen:</p>
                            <h2>Simber's Luxury Private Tours in London for a Complete Experience</h2>
                            <p>Visit the most famous London landmarks, experience the English heritage and enjoy the breathtaking countryside with Simber. London is, without a doubt, one of the best places to visit in a lifetime. All of its history has shaped it to be one of the most interesting locations on the planet, while the modern spirit of the present makes it a cultural melting-pot of today. That is why it is visited by approximately 30 million tourists every year.</p>
                            <p>If you have the chance to visit London or any other destination within  the UK, do it in the most special way – with chauffeur tours of London & the UK. We can offer you a half day chauffeur tour, private London day tours, private day tours in another city or even a long distance tour.</p>
                            <p><a href="https://www.simber.co.uk">Simber</a> will put together an itinerary covering all of the desired destinations for you. Our goal is not only to provide a chauffeur driven service in a premium vehicle, but to provide you with the perfect London private tour to remember.</p>
                            <p>Lets <a href="https://www.simber.co.uk/contact" rel="nofollow">discuss</a> details</p>
                            <img alt="A photo of Windsor Castle, showing one of the possible prive tour destination" className="large-image" src={require("../../images/servicepages/private_1.png")} />
                            <div className="devider"></div>
                            <h2>Popular Places to Visit on a Private Driven Tour in London</h2>
                            <p>Rather than a sightseeing trip by bus, enjoy a completely different luxurious experience with a premium vehicle, a professional London private chauffeur and reliable tours. See the Royal Windsor Castle with private tours in Windsor, fall in love with the vibrant city of Bath, or witness the mystery of Stonehenge. Take a trip to Oxford, Liverpool, or enjoy private chauffeur tours Manchester and feel the different vibes from these famous English cities.</p>
                            <p>At Simber our famous Chauffeurs have local knowledge of historic places and landmarks and can bring you closer to their stories; however, if you want a professional private London tour guide who can add originality to your sightseeing experience, Simber can arrange one for you per request at an additional cost your preferred language (Chinese, Arabic, etc.)</p>
                            <div className="devider"></div>
                            <h2>Simber Exceeds Your Expectations </h2>
                            <p>Not only do you get to see and experience London or any other location within the UK, but also you get to enjoy it in style, comfort, and luxury. Our fleet of cars includes the Mercedes S Class, Mercedes E Class, and Mercedes V Class and extraordinary service.To view the full range of chauffeur driven vehicles please visit our <a href="https://www.simber.co.uk/vehicles" rel="nofollow">Vehicles Page.</a></p>
                            <p>Each one of us, and most importantly, your private chauffeur London will make sure that your safety and comfort are a top priority while you are being entertained during your luxury london private tours by car. We are confident that your tourist thirst will be satisfied.</p>
                            <div className="devider"></div>
                            <h2>Suggested Tours </h2>
                            <h3>5 Hour-Chauffeur Driven Tour in London </h3>
                            <p>This is among the luxury day tours London option which is for everyone who wants to feel the magic of London in a glimpse. Even though it lasts *only* 5 hours, you get to choose which landmarks you want to see. We will happily make custom tours of London it to fit in your plans and we usually recommend that you choose three of London's most defining landmarks including</p>
                            <ul className="ticked-list">
                                <li>The beautiful St Paul’s Cathedral,</li>
                                <li>The iconic Tower Bridge with the 1000 years old fortress the Tower of London</li>
                                <li>The London Eye</li>
                                <li>Private tours of Tower of London</li>
                                <li>Have a chauffeur city of westminster in the English Gothic masterpiece Westminster Palace and Westminster Abbey</li>
                                <li>And the most famous palace in the world, Buckingham Palace.</li>
                            </ul>
                            <p>While touring around London if you decide to stop for a lunch or dinner break during your private tour of London, or to have a classic experience like a leisurely afternoon tea in a top hotel such as The Ritz, Claridge’s or The Lanesborough we are more than happy to arrange an afternoon tea to remember on your behalf. Great luxury tours London right?</p>
                            <p>Apart from arranging the tour, at Simber, we can assist you in purchasing the tickets for all of these incredible places so you avoid the hassle altogether. We’d be happy to do it on your behalf so all you need to do is enjoy the chauffeur tours London hit option.</p>
                            <p>Price: <span className="tour-price">From £300*</span></p>
                            <p>*Price based on Mercedes S-Class or V-Class booking.</p>
                            <p>Please <a href="https://www.simber.co.uk/contact" rel="nofollow">Contact us for a quote.</a></p>
                            <div className="devider"></div>
                            <h3>10 Hour-Chauffeur Driven Tour in London</h3>
                            <p>This is the extended version of our first offer where we recommend you choose an additional 2 to the previously selected 3 landmarks to visit during the 10 hour time window. With this tour you get to see 5 amazing landmarks that witness the beauty of London. Let us note that you can choose more than 5 landmarks to visit; however, you should keep in mind that the optimal number is 5 landmarks as you will spend an hour or two exploring them from the inside as well.</p>
                            <p>You can add dinner, lunch or an afternoon tea break as well. Alternatively, you may decide to do a shopping spree in Harrods, Fortnum & Mason and Selfridges. If you love the outdoors you may decide to take part in other activities like deer-spotting in Richmond park or  take a relaxing walk in Kew Gardens while taking in the view of some of the most fabulous plants from around the world.</p>
                            <p>Price: <span className="tour-price">From £600*</span></p>
                            <p>*Price based on Mercedes S-Class or V-Class booking.</p>
                            <p>Please <a href="https://www.simber.co.uk/contact" rel="nofollow">Contact us for a quote.</a></p>
                            <img alt="A photo of Pultney Bridge, showing one of the possible prive tour destination" className="large-image" src={require("../../images/servicepages/private_2.png")} />
                            <h3>Full Day Tour: Windsor - Stonehenge - Bath</h3>
                            <p>Visit one of England’s most famous historical attractions in just one day with one of our private chauffeur tours to Windsor, Stonehenge, and Bath.</p>
                            <p>This 12 hour-tour will give you enough time to enjoy the cobbled streets and cosy restaurants of the historical city of Windsor and will also take you on a tour in Queen Elizabeth II`s residence, the Windsor Castle and gardens. Our sightseeing service can make it all possible!</p>
                            <p>The next stop is Stonehenge, one of Europe's greatest prehistoric monuments, dating back about 5000 years and thought by some ancient societies to have healing properties.</p>
                            <p>Private tours of Bath from London are what comes next. Bath is the UNESCO World heritage city famous for its Roman-built baths, built in the first centuries of Roman Britain as a grand bathing and socializing complex.</p>
                            <p>This city takes you back in time every step of the way as you admire the great examples of Georgian architecture such as the Royal Crescent or the famous Pulteney Bridge.</p>
                            <p>The tour starts and ends in London.</p>
                            <p>Please <a href="https://www.simber.co.uk/contact" rel="nofollow">Contact us for a quote.</a></p>
                            <div className="notice-card">
                                <span className="notice-header">An Important Note</span>
                                <span className="notice-text">As our tours are completely inspired by our clients’ wishes and desires to visit a certain place, we want to note that if there is a place you want to visit and we haven’t mentioned it as an option, please feel free to contact us with the details and we will tailor-made the sightseeing private tours. It’ll be our pleasure to work according to your itinerary and make sure you have the best time while staying in London.</span>
                            </div>
                            <div className="devider"></div>
                            <h2>Occasions to Use A Private Chauffeur for London Tours</h2>
                            <p>While private tours are possible with any transportation option, chauffeur services raise the level. At Simber, we're dedicated to making any occasion perfect, including these:</p>
                            <ul className="dot-list">
                                <li><strong>Special Events</strong>: Celebrate your honeymoon, birthday, anniversary, or any other important milestone with a chauffeur to make your trip memorable.</li>
                                <li><strong>Limited Time</strong>: When you have limited time in London,  a private chauffeur will help you get around efficiently.</li>
                                <li><strong>A Stress-Free Experience</strong>: If you want to relax and enjoy your trip without stressing about parking spots and traffic jams.</li>
                                <li><strong>For a Personalized Experience</strong>: Chauffeurs tailor the tours according to your interests while recommending things to do and see in London.</li>
                            </ul>
                            <div className="devider"></div>
                            <h2 className="faq-section-header">Private Tours FAQ</h2>                    
                            <div className="faq-wrapper" itemScope itemType="https://schema.org/FAQPage">
                                <AccordionComponent
                                    question="What should I bring with me?" 
                                    answer="There are essentials in each of our vehicles; water, mints, tissues, phone chargers and sanitiser gel. Some of our clients usually bring some snacks for the journey."/>
                                <AccordionComponent
                                    question="What is the departure point?" 
                                    answer="We will depart as per your request, stopping to pick up friends or family before or after is also no problem. Please let us know prior to confirming if there are any additional pickups you would like your chauffeur to make before collecting the lead passenger and starting the England private tours."
                                />
                                <AccordionComponent
                                    question="What’s the duration of the tour?" 
                                    answer="The duration of your private tour London or another city depends on the tour chosen, in general, our half day London tour is four hours, a full day London tour is eight hours. For custom tours of the uk heading outside of London the duration will vary on the itinerary as well as the distance in general a chauffeur service Windsor, Bath and Stonehenge can take approximately ten hours."
                                />
                                <AccordionComponent
                                    question="Where is the finish point?" 
                                    answer="The finishing point is as per your request, stopping to drop off friends or family is no problem for our London tour chauffeurs. Please let us know prior to confirming if there are any additional drop offs you would like your chauffeur to make before dropping off the lead passenger."
                                />
                                <AccordionComponent
                                    question="Can I bring luggage with me?" 
                                    answer="Yes absolutely, you can bring luggage with you. Please don't forget to pack a camera to catch every amazing moment during your private sightseeing tour."
                                />
                                <AccordionComponent
                                    question="I want to recommend Simber for Private Tour Chauffeur Service. How do I do that?" 
                                    answer="You can share some photos of your special day on social media or provide our contact details to your family, friends or colleagues. We’d  also appreciate it if you could leave us a review and share how your private chauffeur driven tours experience was, and you can also share that we offer custom tours of England for every taste."
                                />
                                <AccordionComponent
                                    question="Can I use a chauffeured private tour from London to Scotland?" 
                                    answer="Yes, even though our chauffeur service primarily operates in London, we can extend the services for multi-day trips to Scotland upon request. This would be a perfect option for a luxurious and personalized travel experience."
                                />
                                <AccordionComponent
                                    question="Can I hire a chauffeur for a daily private tour?" 
                                    answer="While some companies might specialize in multi-day tours, it's very likely you can hire a chauffeur for a daily private tour within London or the surrounding areas. This would be ideal if you have specific places you want to visit within a shorter timeframe."
                                />
                            </div>
                        </div>
                    </Container>
                </main>
                <FooterComponent/>
            </div>
        </div>
    )
}