import React from "react";
import { Container } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import MetaTags from 'react-meta-tags';

export function PaymentCanceledPage() {
  return (
    <div>
      <MetaTags>
          <meta name="description" content="Simber Chauffeur Services Payment Cancelled page" />
          <title>Payment Cancelled | Simber</title>
      </MetaTags>
      <HeaderComponent />
      <main>
        <Container>
          <span>Payment Canceled!</span>
          <span>
            You can try again or contact us if you think this is bug at: email@email.com
          </span>
          <NavLink to="/" className="continue-button">
              Return to Homepage
          </NavLink>
        </Container>
      </main>
      <FooterComponent />
    </div>
  );
}
