import React, { ReactElement, useEffect, useState } from 'react';
import './ProfilePageWrapper.scss'
import HeaderComponent from '../HeaderComponent'
import FooterComponent from '../FooterComponent'
import { Redirect } from 'react-router';
import { tr } from 'date-fns/locale';
import { connect } from 'react-redux';
import { RootState } from '../../redux/reducers/rootReducer';
import MetaTags from 'react-meta-tags';

export enum ProfilePageTab {
    Profile,
    ProfileEdit,
    MyBookings,
    BookingDetails,
    Payment,
    Settings
}

export interface ProfilePageProps {
    initTab: ProfilePageTab;
    children: ReactElement;
}

interface IMappedProps {
    name: string;
}

type Props = IMappedProps & ProfilePageProps

function ProfilePageWrapper(props: Props) {

    const [activeTab, setActiveTab] = useState(props.initTab);
    const [redirect, setRedirect] = useState("");
    const [menuOpen, setMenuOpen] = useState(false);
    const [error, setError] = useState("");

    useEffect(() => {
        switchActiveTab(props.initTab);
    })
    
    const switchActiveTab = (tab: ProfilePageTab) => {

        if(redirect !== "") {
            setRedirect("");
        }
        else {
            Array.prototype.forEach.call(document.getElementsByClassName("tab-link"), function(e: Element) {
                e.className = "tab-link";
            });

            switch(tab) {
                case ProfilePageTab.BookingDetails:
                case ProfilePageTab.MyBookings: {
                    document.querySelectorAll("#bookings-tab-link").forEach(e => {e.className += " active";});
                    break;
                }
                case ProfilePageTab.ProfileEdit:
                case ProfilePageTab.Profile: {
                    document.querySelectorAll("#profile-tab-link").forEach(e => {e.className += " active";});
                    break;
                }
                case ProfilePageTab.Payment: {
                    document.querySelectorAll("#payment-tab-link").forEach(e => {e.className += " active";});
                    break;
                }
                case ProfilePageTab.Settings: {
                    document.querySelectorAll("#settings-tab-link").forEach(e => {e.className += " active";});
                    break;
                }
            }
            setActiveTab(tab);
        }
    }

    if(redirect !== "") {
        return (<Redirect push to={"/profile/" + redirect} />);
    }
    else {
        return(
            <div className="wrapper">
                <MetaTags>
                    <meta name="description" content="Simber Chauffeur Services Profile" />
                    <title>Profile | Simber</title>
                </MetaTags>
                <HeaderComponent />
                <main className="profile-card">
                    <h1 className="profile-header">Welcome back, <span>{props.name}</span></h1>                    
                    <div className="profile-card-content">
                        <div className="menu-hamburger">
                            {menuOpen &&
                                <React.Fragment>
                                    <div className={ (activeTab === ProfilePageTab.Profile || activeTab === ProfilePageTab.ProfileEdit) ? "profile-menu-row" : "profile-menu-row first"}>
                                        <span id="profile-tab-link" className="tab-link" onClick={() => {setMenuOpen(false); setRedirect("data"); }}>Profile</span>
                                        {activeTab === ProfilePageTab.Profile && <img src={require("../../images/pictograms/arrow.png")}/> }
                                        {activeTab === ProfilePageTab.ProfileEdit && <img src={require("../../images/pictograms/arrow.png")}/> }
                                    </div>
                                    <div className={ activeTab === ProfilePageTab.MyBookings ? "profile-menu-row" : "profile-menu-row first"}>
                                        <span id="bookings-tab-link" className="tab-link" onClick={() => {setMenuOpen(false); setRedirect("bookings")}}>My Bookings</span>
                                        {activeTab === ProfilePageTab.MyBookings && <img src={require("../../images/pictograms/arrow.png")}/> }
                                        {activeTab === ProfilePageTab.BookingDetails && <img src={require("../../images/pictograms/arrow.png")}/> }
                                    </div>
                                    <div className={ activeTab === ProfilePageTab.Payment ? "profile-menu-row" : "profile-menu-row first"}>
                                        <span id="payment-tab-link" className="tab-link" onClick={() => {setMenuOpen(false); setRedirect("payment")}}>Payment Method</span>
                                        {activeTab === ProfilePageTab.Payment && <img src={require("../../images/pictograms/arrow.png")}/> }
                                    </div>
                                    <div className={ activeTab === ProfilePageTab.Settings ? "profile-menu-row" : "profile-menu-row first"}>
                                        <span id="settings-tab-link" className="tab-link" onClick={() => {setMenuOpen(false); setRedirect("settings")}}>Settings</span>
                                        {activeTab === ProfilePageTab.Settings && <img src={require("../../images/pictograms/arrow.png")}/> }
                                    </div>
                                </React.Fragment>
                            }
                            {menuOpen ||
                                <React.Fragment>
                                    {activeTab === ProfilePageTab.Profile &&
                                        <div className="profile-menu-row">
                                            <span id="profile-tab-link" className="tab-link" onClick={() => setMenuOpen(true)}>Profile</span>
                                            <img src={require("../../images/pictograms/arrow.png")}/>
                                        </div>
                                    }
                                    {activeTab === ProfilePageTab.ProfileEdit &&
                                        <div className="profile-menu-row">
                                            <span id="profile-tab-link" className="tab-link" onClick={() => setMenuOpen(true)}>Profile</span>
                                            <img src={require("../../images/pictograms/arrow.png")}/>
                                        </div>
                                    }
                                    {activeTab === ProfilePageTab.MyBookings &&
                                        <div className="profile-menu-row">
                                            <span id="bookings-tab-link" className="tab-link" onClick={() => setMenuOpen(true)}>My Bookings</span>
                                            <img src={require("../../images/pictograms/arrow.png")}/>
                                        </div>
                                    }
                                    {activeTab === ProfilePageTab.BookingDetails &&
                                        <div className="profile-menu-row">
                                            <span id="bookings-tab-link" className="tab-link" onClick={() => setMenuOpen(true)}>My Bookings</span>
                                            <img src={require("../../images/pictograms/arrow.png")}/>
                                        </div>
                                    }
                                    {activeTab === ProfilePageTab.Payment &&
                                        <div className="profile-menu-row">
                                            <span id="payment-tab-link" className="tab-link" onClick={() => setMenuOpen(true)}>Payment Method</span>
                                            <img src={require("../../images/pictograms/arrow.png")}/>
                                        </div>
                                    }
                                    {activeTab === ProfilePageTab.Settings &&
                                        <div className="profile-menu-row">
                                            <span id="settings-tab-link" className="tab-link" onClick={() => setMenuOpen(true)}>Settings</span>
                                            <img src={require("../../images/pictograms/arrow.png")}/>
                                        </div>
                                    }
                                </React.Fragment>
                            }
                        </div>
                        <div className="menu">
                            <div className="profile-menu-row">
                                <span id="profile-tab-link" className="tab-link" onClick={() => setRedirect("data")}>Profile</span>
                                {activeTab === ProfilePageTab.Profile && <img src={require("../../images/pictograms/arrow.png")}/> }
                                {activeTab === ProfilePageTab.ProfileEdit && <img src={require("../../images/pictograms/arrow.png")}/> }
                            </div>
                            <div className="profile-menu-row">
                                <span id="bookings-tab-link" className="tab-link" onClick={() => setRedirect("bookings")}>My Bookings</span>
                                {activeTab === ProfilePageTab.MyBookings && <img src={require("../../images/pictograms/arrow.png")}/> }
                                {activeTab === ProfilePageTab.BookingDetails && <img src={require("../../images/pictograms/arrow.png")}/> }
                            </div>
                            <div className="profile-menu-row">
                                <span id="payment-tab-link" className="tab-link" onClick={() => setRedirect("payment")}>Paymnet Method</span>
                                {activeTab === ProfilePageTab.Payment && <img src={require("../../images/pictograms/arrow.png")}/> }
                            </div>
                            <div className="profile-menu-row">
                                <span id="settings-tab-link" className="tab-link" onClick={() => setRedirect("settings")}>Settings</span>
                                {activeTab === ProfilePageTab.Settings && <img src={require("../../images/pictograms/arrow.png")}/> }
                            </div>
                        </div>
                        <div className="content">
                            {props.children}
                        </div>
                    </div>
                </main>
                <FooterComponent />
            </div>
        )
    }
}

const mapStateToProps = (state: RootState): IMappedProps => {
    return{ name: state.user.name }
};

export default connect(
    mapStateToProps
)(ProfilePageWrapper)