import React, { useState } from 'react';
import { blogPosts, featuredPosts, tags, tastemonials } from '../config/texts';
import BlogPost from '../dtos/BlogPost';
import { Tastemonial } from '../dtos/Tastemonial';
import MetaTags from 'react-meta-tags';
import HeaderComponent from './HeaderComponent';
import { Container } from '@material-ui/core';
import Carousel from 'react-multi-carousel';
import FooterComponent from './FooterComponent';
import { Redirect } from 'react-router';
import { useEffect } from 'react';
import './BlogDetailPage.scss'
import StructuredBreadcrumb, { IBreadcrumb } from './BreadCrumbs';
import { isDebuggerStatement } from 'typescript';
import classNames from 'classnames';

export default function BlogDetailPage() {

    const [detailsRedirect, setDetailsRedirect] = useState("");
    const [oldUrlRedirect, setOldUrlRedirect] = useState("");
    const [servicesRedirect, setServicesRedirect] = useState("");
    const [post, setPost] = useState<BlogPost>();
    window.scrollTo({top: 0, behavior: "smooth"});

    useEffect(() => {
        var x = window.location.href.split("/");
        var urlTitle = x[x.length-1];
        var splitTitle = urlTitle.split("-");
        //There could be one '-' in old ULRs
        if(splitTitle.length < 3 && urlTitle.includes("_")){
            setOldUrlRedirect(urlTitle.split("_").join("-").toLowerCase());
            return;
        }
        setPost(blogPosts.find(bp => bp.slug == splitTitle.join(" ")));
        if(detailsRedirect != "") {
            setDetailsRedirect("");
        }
        if(oldUrlRedirect != "") {
            setOldUrlRedirect("");
        }
    })

    function getRandom(arr: Array<any>, n: number) {
        var result = new Array(n),
            len = arr.length,
            taken = new Array(len);
        if (n > len)
            throw new RangeError("getRandom: more elements taken than available");
        while (n--) {
            var x = Math.floor(Math.random() * len);
            result[n] = arr[x in taken ? taken[x] : x];
            taken[x] = --len in taken ? taken[len] : len;
        }
        return result;
    }

    var bgStyle = {
        //backgroundImage: 'url(' + require(('../images/blogposts/' + bg + '0.webp')) + ')',
      };

    const getHtml = () => {
        var number = 0;
        var detailsString = post?.details.replace(/HÁREF/g, "href='");
        detailsString = detailsString?.replace(/SÓRSZ/g, "src=");
        var x = detailsString?.split("'[source]'") ?? [];
        var temp: string[] = [];
        x.forEach(element => {
            temp.push(element.replace("src=",
            function () {return "src=" + (require("../images/blogposts/" + post?.slug.split(" ").join("-") + (number++) + ".webp"));
            }));
        });
        var i = 0;
        var list = document.getElementsByTagName("h2");
        for(i ;i < list.length; i++) {
            (list[i] as HTMLElement).classList.add("details-h2");
            (list[i] as HTMLElement).classList.remove("gold");
        }
        return temp.join("");
    }

    const randTestemonials: Tastemonial[] = getRandom(tastemonials,2);

    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Blogs",
            url: "https://www.simber.co.uk/blog"
        },
        {
          description: post?.titleFull ?? "",
          url: "https://www.simber.co.uk/blog/" + window.location.href.split("/")[window.location.href.split("/").length-1]
        },
    ]

    if(detailsRedirect != "") {
        return( <Redirect push to={"/blog/" + detailsRedirect} />)
    }
    else
    if(oldUrlRedirect != "") {
        return( <Redirect push to={"/blog/" + oldUrlRedirect} />)
    }
    else
    if(servicesRedirect != "") {
        window.scroll({top: 0});
        return( <Redirect push to={"/services/" + servicesRedirect} />)
    }
    return (
        <div className="service-background-wrapper blog">
            <MetaTags>
                <meta name="description" content={post?.metaDesc} />
                <title>{post?.metaTiltle}</title>
                <link rel="canonical" href={"https://www.simber.co.uk/blog/" + window.location.href.split("/")[window.location.href.split("/").length-1]} />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent />
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <div className="container details-page details-page">
                        <h1 className="title">{post?.titleFull ?? post?.slug}</h1>
                        <div className="title-data">
                            <div>
                                <img src={require("../images/pictograms/pen_pictogram.png")}/>
                                <span>Posted by {post?.author}</span>
                            </div>
                            <div>
                                <img src={require("../images/pictograms/calendar_pictogram.png")}/>
                                <span>{post?.date.getDate() + "." + ((post?.date.getMonth() != undefined && post?.date.getMonth() !== null) ? (post?.date.getMonth() + 1) : "" ) + "." + post?.date.getFullYear()}</span>
                            </div>
                        </div>
                        <img onClick={() => window.scrollTo({top: 0, behavior: "smooth"})} className="top-img" src={require("../images/pictograms/top_arrow.png")}/>
                        <div style={bgStyle} className="blog-wrapper service-picture"></div>
                        <div className="content">
                        
                            <div className="main-section">
                                <div dangerouslySetInnerHTML={{__html: getHtml()}} className="posts"></div>
                                <div className="sidebar">
                                    <div>
                                        <h2>Testimonials</h2>
                                        {randTestemonials.map(t => {
                                            return(
                                                <div className="tastemonial-card open">
                                                    <span className="tastemonial-text">{t.text}</span>
                                                    <span className="tastemonial-name">{t.name}</span>
                                                    <img alt="Pictogram of stars, representing a rating of 5." src={require("../images/pictograms/stars.png")}/>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="featured-posts-sidebar">
                                        <h2>Featured posts</h2>
                                        {featuredPosts.map(x => {
                                            return (
                                                <div className="featured-post-card" onClick={() => setDetailsRedirect(x.slug.split(" ").join("-"))}>
                                                    <img src={require("../images/blogposts/" + x.slug.split(" ").join("-") + "0.webp")} />
                                                    <div className="data">
                                                        <h3><a className="placeholder-link" href={"https://www.simber.co.uk/blog/" + x.slug.split(" ").join("-")}>{x.titleFull}</a></h3>
                                                        <div><img src={require("../images/pictograms/calendar_pictogram.png")}/><span>{x.date.getDate() + "." + (x.date.getMonth() + 1) + "." + x.date.getFullYear()}</span></div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className="services-menu">
                                        <h2>Services</h2>
                                        <div onClick={() => setServicesRedirect("airport-transfers")}><span>Airport Transfers</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("long-term-chauffeur")}><span>Long Term Chauffeuring</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("long-distance-chauffeur")}><span>Long Distance Chauffeuring</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("corporate-chauffeur")}><span>Corporate Chauffeurs</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("events-chauffeurs")}><span>Event Chauffeuring</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("wedding-chauffeur")}><span>Weddings</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("private-tours")}><span>Private Tours</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div onClick={() => setServicesRedirect("seaport-transfers")}><span>Cruise Port Transfers</span><img src={require("../images/pictograms/arrow.png")}/></div>
                                        <div className='hide'>
                                            <a className="link" href="https://www.simber.co.uk/services/airport-transfers" ><span className="menu-item-text">Airport Transfers</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/long-term-chauffeur" ><span className="menu-item-text">Long Term Chauffeuring</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/long-distance-chauffeur" ><span className="menu-item-text">Long Distance Chauffeuring</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/corporate-chauffeur" ><span className="menu-item-text">Corporate Chauffeur</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/events-chauffeurs" ><span className="menu-item-text">Event Chauffeuring</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/wedding-chauffeur" ><span className="menu-item-text">Weddings</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/private-tours" ><span className="menu-item-text">Private Tours</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/seaport-transfers" ><span className="menu-item-text">Cruise Port Transfers</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/strategic-partnerships-hotel-transport" ><span className="menu-item-text">Strategic Partnership - Hotels</span></a>
                                            <a className="link" href="https://www.simber.co.uk/services/luxury-minibus-hire" ><span className="menu-item-text">Luxury Minibus Hire</span></a>
                                        </div>
                                    </div>
                                    <div className="tags-wrapper">
                                        <h2>Post tags</h2>
                                        <div className="tags">
                                            {tags.map((x,i) => {
                                                return (
                                                    <div className={i !== (tags.length-1) ? "tag margin" : "tag"}>{x}</div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                <FooterComponent />
            </div>
        </div>
    )
}