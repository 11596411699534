import React, { useState, useEffect, useLayoutEffect, createRef, useRef } from 'react'
import { Button, Dialog, DialogContent, Menu, MenuItem } from '@material-ui/core'
import { connect } from 'react-redux'
import "./HeaderComponent.scss"
import Navbar from "react-bootstrap/Navbar";
import { RootState } from '../redux/reducers/rootReducer';
import { Redirect } from 'react-router';
import { UserActionTypes, SummaryActionTypes } from '../redux/actions';
import { bindActionCreators, Dispatch } from 'redux';
import { clearState, clearUserState } from "../redux/actions"
import { redirectToFb, redirectToInsta } from "../config/Contacts"
import { axiosInstance } from '../config/Axios.config';
import { useIdleTimer } from 'react-idle-timer'
import NestedMenuItem from 'material-ui-nested-menu-item';

interface IMappedProps {
    email: string;
    name: string;
    token: string;
}

interface IDispatchedProps {
    clearState: () => void;
    clearUserState: () => void;
}

type Props = IMappedProps & IDispatchedProps;

function HeaderComponent(props: Props) {
    const [redirect,setRedirect] = useState(false);
    const [homeRedirect,setHomeRedirect] = useState(false);
    const [blogRedirect,setBlogRedirect] = useState(false);
    const [init,setInit] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [profileRedirect,setProfileRedirect] = useState("");
    const [login,setLogin] = useState(false);
    const [vehiclesRedirect,setVehiclesRedirect] = useState(false);
    const [galleryRedirect,setGalleryRedirect] = useState(false);
    const [accountRedirect,setAccountRedirect] = useState(false);
    const [scroll, setScroll] = useState("");
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);
    const [logoutConfirmOpen, setLogoutConfirmOpen] = useState(false);
    const active = document.URL.split("/").pop();
    const [expanded, setExpanded] = useState(false);
    const [headerHeight, setHeaderHeight] = useState('auto');

    useEffect(() => {
        if(expanded) {
            document.documentElement.style.height = '100vh';
            setHeaderHeight('100vh');
            document.documentElement.style.overflow = 'hidden';
        }
        else {
            document.documentElement.style.height = '';
            setHeaderHeight('auto');
            document.documentElement.style.overflow = 'auto';
        }
    },[expanded])

    const handleOnIdle = (event: any) => {
        if(props.token)
            onLogoutClick();
    }
    
    const handleOnActive = (event: any) => {
    }
    
    const handleOnAction = (event: any) => {
    }
    
    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * + Number("15")!,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500
    })

    const refEl = useRef(null);

    const setSubmenuEffects = () => {
        setTimeout(
            () => {
                if(!refEl.current) {
                    setAnchorEl(null);
                }
            }, 300);
    }

    useEffect(() => {
        if(!loaded) {
            setLoaded(true);
        }
        if(init && loaded){
            var menu = document.getElementById("services-menu");
            var div = menu?.children[2];
            (div as HTMLElement)!.onmouseleave = setSubmenuEffects
            setInit(false);
        }
        if(blogRedirect) {
            setBlogRedirect(false);
        }
        if(redirect) {
            setRedirect(false);
            setScroll("");
            props.clearState();
            props.clearUserState();
        }
        if(homeRedirect) {
            setHomeRedirect(false);
        }
        if(profileRedirect !== "") {
            setProfileRedirect("")
            setAccountMenuOpen(false);
        }
        if(login)
            setLogin(false);
        if(vehiclesRedirect)
            setVehiclesRedirect(false);
        if(galleryRedirect)
            setGalleryRedirect(false);
        if(scroll !== "") {
            setScroll("");
        }
        if(accountRedirect) {
            setAccountRedirect(false);
        }
    })

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if(Boolean(anchorEl)){
            setAnchorEl(null);
        } else {
            setAnchorEl(event.currentTarget);
        }        
    };

    const onLogoutClick = () => {
        axiosInstance.defaults.headers["Authorization"] = "Bearer " + props.token;
        axiosInstance.get("user/logout")
            .then(res => {

            })
            .catch(err => {})
        var c = localStorage.getItem("cookie");
        localStorage.clear();
        if(c)
            localStorage.setItem("cookie","true")
        setLogoutConfirmOpen(false);
        setRedirect(true);
    };
    
    if(redirect) {                      
        return (<Redirect push to={{pathname:"/home", state:{}}} />)
    }
    else
    if(homeRedirect) {
        return (<Redirect push to={{pathname:"/home", state:{}}} />)
    }
    else
    if(blogRedirect) {
        return (<Redirect push to={{pathname:"/blog", state:{}}} />)
    }
    else
    if(profileRedirect !== "") {
        return (<Redirect push to={{pathname: "/profile/" + profileRedirect, state:{}}} />)
    }
    else
    if(scroll !== "") {                      
        return (<Redirect push to={{pathname:"/" + scroll,state:{}}}  />)
    }
    else
    if(login)
        return (<Redirect push to="/login" />)
    else
    if(vehiclesRedirect)
        return (<Redirect push to="/vehicles" />)
    else
    if(galleryRedirect)
        return (<Redirect push to="/gallery" />)
    else
    if(accountRedirect)
        return (<Redirect push to="/account" />)
    else {
        return (
            <header className={expanded ? "header-scroll" : ""} style={{height: headerHeight}}>
                <Navbar 
                    variant="dark"
                    expand="lg"
                    id="header-container"
                    className={expanded ? "header-container open" : "header-container"}
                    expanded={expanded}
                    onToggle={(e) => {
                        setExpanded(!expanded);
                    }}>
                    <img
                        onClick={() => setHomeRedirect(true)}
                        alt="Logo of Simber, black and golden reading 'Simber Chauffeur Services'."
                        className="header-img-collapsed"
                        src={require("../images/color_logo.png")}
                    ></img>
                    <Navbar.Toggle />
                    <div className="start">
                        <img
                            onClick={redirectToFb}
                            alt="Pictogram of Facebook."
                            src={require("../images/pictograms/fb.png")}
                        ></img>
                        <img
                            onClick={redirectToInsta}
                            alt="Pictogram of Instagram."
                            src={require("../images/pictograms/insta.png")}
                        ></img>
                    </div>
                    <Navbar.Collapse className="menu" >
                        
                        <Button onClick={() => setScroll("about")} className={active === "about" ? "active" : ""}>
                            <a className="link" href="https://www.simber.co.uk/about" >About Us</a>
                        </Button>
                        <Button 
                            className={
                                Boolean(anchorEl) 
                                ? (
                                    (active ==="airport-transfers" || active ==="long-term-chauffeur" || active ==="long-distance-chauffeur" || active ==="corporate-chauffeur" || active ==="executive-transfers" || active ==="events-chauffeurs" || active ==="wedding-chauffeur" || active ==="private-tours" || active ==="seaport-transfers" || active === "heathrow" || active === "gatwick" ) 
                                        ? "services-menu-button open active" 
                                        :"services-menu-button open"
                                ) 
                                : (
                                    (active ==="airport-transfers" || active ==="long-term-chauffeur" || active ==="long-distance-chauffeur" || active ==="corporate-chauffeur" || active ==="executive-transfers" || active ==="events-chauffeurs" || active ==="wedding-chauffeur" || active ==="private-tours" || active ==="seaport-transfers" || active === "heathrow" || active === "gatwick") 
                                        ? "services-menu-button active" 
                                        : "services-menu-button"
                                )
                            }
                            aria-controls="services-menu"
                            aria-haspopup="true"
                            onClick={handleClick}
                            onMouseOver={(event) =>{if(window.innerWidth > 991) setAnchorEl(event.currentTarget)}}
                        >
                            <span>Services</span>
                            <div className='hide'>
                                <a className="link" href="https://www.simber.co.uk/services/airport-transfers" ><span className="menu-item-text">Airport Transfers</span></a>
                                <a className="link" href="https://www.simber.co.uk/services/airport-transfers/heathrow" ><span className="menu-item-text">Luxury Airport Transfer to Heathrow</span></a>
                                <a className="link" href="https://www.simber.co.uk/services/airport-transfers/gatwick" ><span className="menu-item-text">Luxury Airport Transfer to Gatwick</span></a>
                                <a className="link" href="https://www.simber.co.uk/services/long-term-chauffeur" ><span className="menu-item-text">Long Term Chauffeuring</span></a>
                                <a className="link" href="https://www.simber.co.uk/services/long-distance-chauffeur" ><span className="menu-item-text">Long Distance Chauffeuring</span></a>
                                <a className="link" href="https://www.simber.co.uk/services/corporate-chauffeur" ><span className="menu-item-text">Corporate Chauffeur</span></a>
                                <a className="link" href="https://www.simber.co.uk/services/executive-transfers" ><span className="menu-item-text">Executive Chauffeur</span></a>
                                <a className="link" href="https://www.simber.co.uk/services/events-chauffeurs" ><span className="menu-item-text">Event Chauffeuring</span></a>
                                <a className="link" href="https://www.simber.co.uk/services/wedding-chauffeur" ><span className="menu-item-text">Weddings</span></a>
                                <a className="link" href="https://www.simber.co.uk/services/private-tours" ><span className="menu-item-text">Private Tours</span></a>
                                <a className="link" href="https://www.simber.co.uk/services/seaport-transfers" ><span className="menu-item-text">Cruise Port Transfers</span></a>
                                <a className="link" href="https://www.simber.co.uk/services/strategic-partnerships-hotel-transport" ><span className="menu-item-text">Strategic Partnership - Hotels</span></a>
                                <a className="link" href="https://www.simber.co.uk/services/luxury-minibus-hire" ><span className="menu-item-text">Luxury Minibus Hire</span></a>
                            </div>
                            <img src={require("../images/pictograms/arrow.png")} />
                        </Button>
                        <Menu
                            id="services-menu"
                            anchorEl={anchorEl}
                            keepMounted={true}
                            className="service-menu"
                            open={Boolean(anchorEl)}
                            onClose={() => {setScroll(""); setAnchorEl(null);}}>
                                <NestedMenuItem
                                 
                                 label={<a className="link" href="https://www.simber.co.uk/services/airport-transfers" ><span className="menu-item-text">Airport Transfers</span></a>} focusVisibleClassName={'focus'} parentMenuOpen={Boolean(anchorEl)} className={active === "airport-transfers" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/airport-transfers")}}>
                                    <div
                                    ref={refEl}>
                                        <MenuItem className={active === "heathrow" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/airport-transfers/heathrow")}}><a className="link" href="https://www.simber.co.uk/services/airport-transfers/heathrow" ><span className="menu-item-text">Luxury Airport Transfer to Heathrow</span></a></MenuItem>
                                        <MenuItem className={active === "gatwick" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/airport-transfers/gatwick")}}><a className="link" href="https://www.simber.co.uk/services/airport-transfers/gatwick" ><span className="menu-item-text">Luxury Airport Transfer to Gatwick</span></a></MenuItem>
                                    </div>
                                </NestedMenuItem>
                                <MenuItem className={active === "long-term-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/long-term-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/long-term-chauffeur" ><span className="menu-item-text">Long Term Chauffeuring</span></a></MenuItem>
                                <MenuItem className={active === "long-distance-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/long-distance-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/long-distance-chauffeur" ><span className="menu-item-text">Long Distance Chauffeuring</span></a></MenuItem>
                                <MenuItem className={active === "corporate-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/corporate-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/corporate-chauffeur" ><span className="menu-item-text">Corporate Chauffeur</span></a></MenuItem>
                                <MenuItem className={active === "executive-transfers" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/executive-transfers")}}><a className="link" href="https://www.simber.co.uk/services/executive-transfers" ><span className="menu-item-text">Executive Chauffeur</span></a></MenuItem>
                                <MenuItem className={active === "events-chauffeurs" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/events-chauffeurs")}}><a className="link" href="https://www.simber.co.uk/services/events-chauffeurs" ><span className="menu-item-text">Event Chauffeuring</span></a></MenuItem>
                                <MenuItem className={active === "wedding-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/wedding-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/wedding-chauffeur" ><span className="menu-item-text">Weddings</span></a></MenuItem>
                                <MenuItem className={active === "private-tours" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/private-tours")}}><a className="link" href="https://www.simber.co.uk/services/private-tours" ><span className="menu-item-text">Private Tours</span></a></MenuItem>
                                <MenuItem className={active === "seaport-transfers" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/seaport-transfers")}}><a className="link" href="https://www.simber.co.uk/services/seaport-transfers" ><span className="menu-item-text">Cruise Port Transfers</span></a></MenuItem>
                                <MenuItem className={active === "strategic-partnerships-hotel-transport" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/strategic-partnerships-hotel-transport")}}><a className="link" href="https://www.simber.co.uk/services/strategic-partnerships-hotel-transport" ><span className="menu-item-text">Strategic Partnership - Hotels</span></a></MenuItem>
                                <MenuItem className={active === "luxury-minibus-hire" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/luxury-minibus-hire")}}><a className="link" href="https://www.simber.co.uk/services/luxury-minibus-hire" ><span className="menu-item-text">Luxury Minibus Hire</span></a></MenuItem>
                        </Menu>
                        {Boolean(anchorEl) &&
                            <div className="service-menu-mobil">
                                <MenuItem className={active === "airport-transfers" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/airport-transfers")}}><a className="link" href="https://www.simber.co.uk/services/airport-transfers" ><span className="menu-item-text">Airport Transfers</span></a></MenuItem>
                                <div className='small-devider'></div>
                                <MenuItem className={active === "heathrow" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/airport-transfers/heathrow")}}><a className="link" href="https://www.simber.co.uk/services/airport-transfers/heathrow" ><span className="menu-item-text">Luxury Airport Transfer to Heathrow</span></a></MenuItem>
                                <MenuItem className={active === "gatwick" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/airport-transfers/gatwick")}}><a className="link" href="https://www.simber.co.uk/services/airport-transfers/gatwick" ><span className="menu-item-text">Luxury Airport Transfer to Gatwick</span></a></MenuItem>
                                <div className='small-devider'></div>
                                <MenuItem className={active === "long-term-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/long-term-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/long-term-chauffeur" ><span className="menu-item-text">Long Term Chauffeuring</span></a></MenuItem>
                                <MenuItem className={active === "long-distance-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/long-distance-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/long-distance-chauffeur" ><span className="menu-item-text">Long Distance Chauffeuring</span></a></MenuItem>
                                <MenuItem className={active === "corporate-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/corporate-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/corporate-chauffeur" ><span className="menu-item-text">Corporate Chauffeur</span></a></MenuItem>
                                <MenuItem className={active === "executive-transfers" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/executive-transfers")}}><a className="link" href="https://www.simber.co.uk/services/executive-transfers" ><span className="menu-item-text">Executive Chauffeur</span></a></MenuItem>
                                <MenuItem className={active === "events-chauffeurs" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/events-chauffeurs")}}><a className="link" href="https://www.simber.co.uk/services/events-chauffeurs" ><span className="menu-item-text">Event Chauffeuring</span></a></MenuItem>
                                <MenuItem className={active === "wedding-chauffeur" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/wedding-chauffeur")}}><a className="link" href="https://www.simber.co.uk/services/wedding-chauffeur" ><span className="menu-item-text">Weddings</span></a></MenuItem>
                                <MenuItem className={active === "private-tours" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/private-tours")}}><a className="link" href="https://www.simber.co.uk/services/private-tours" ><span className="menu-item-text">Private Tours</span></a></MenuItem>
                                <MenuItem className={active === "seaport-transfers" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/seaport-transfers")}}><a className="link" href="https://www.simber.co.uk/services/seaport-transfers" ><span className="menu-item-text">Cruise Port Transfers</span></a></MenuItem>
                                <MenuItem className={active === "strategic-partnerships-hotel-transport" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/strategic-partnerships-hotel-transport")}}><a className="link" href="https://www.simber.co.uk/services/strategic-partnerships-hotel-transport" ><span className="menu-item-text">Strategic Partnership - Hotels</span></a></MenuItem>
                                <MenuItem className={active === "luxury-minibus-hire" ? "active" : ""} onClick={() => {setAnchorEl(null); setScroll("services/luxury-minibus-hire")}}><a className="link" href="https://www.simber.co.uk/services/luxury-minibus-hire" ><span className="menu-item-text">Luxury Minibus Hire</span></a></MenuItem>
                            </div>
                        }
                        
                        <Button onClick={() => setVehiclesRedirect(true)} className={active === "vehicles" ? "active" : ""}>
                            <a className="link" href="https://www.simber.co.uk/vehicles" >Vehicles</a>
                        </Button>
                        <img
                        onClick={() => setHomeRedirect(true)}
                        className="header-img"
                        alt="Logo of Simber, black and golden reading 'Simber Chauffeur Services'."
                        src={require("../images/color_logo.png")}
                        ></img>
                        
                        <Button onClick={() => setBlogRedirect(true)} className={active === "blog" ? "active" : ""}>
                            <a className="link" href="https://www.simber.co.uk/blog" >Blog</a>
                        </Button>
                        
                        <Button onClick={() => setGalleryRedirect(true)} className={active === "gallery" ? "active" : ""}>
                            <a className="link" href="https://www.simber.co.uk/gallery" >Gallery</a>
                        </Button>
                        
                        <Button onClick={() => setScroll("contact")}  className={active === "contact" ? "active" : ""}>
                            <a className="link" href="https://www.simber.co.uk/contact" >Contact Us</a>
                        </Button>
                        {props.token === "" ?
                        (
                            <Button onClick={() => setLogin(true)} className={(active === "login" || active === "register") ? "active login" : "login"}>
                                <a className="link" href="https://www.simber.co.uk/login" >Sign In/ Sign Up</a>
                            </Button>
                        ) :
                        (
                            <div className="logout desktop">
                                <div className="closed" onClick={() => setAccountMenuOpen(!accountMenuOpen)}>
                                    <span>{props.name}</span>
                                    {accountMenuOpen || <img src={require("../images/pictograms/arrow.png")} />}
                                    {accountMenuOpen && <img className="open" src={require("../images/pictograms/arrow.png")} />}
                                </div>                            
                                {accountMenuOpen &&
                                    <div className="account">
                                        <span className={active === "data" ? "active" : ""} onClick={() => setProfileRedirect("data")}><a className="link" href="https://www.simber.co.uk/profile/data" >Profile</a></span>
                                        <span className={active === "bookings" ? "active" : ""} onClick={() => setProfileRedirect("bookings")}><a className="link" href="https://www.simber.co.uk/profile/bookings" >My Bookings</a></span>
                                        <span className={active === "payment" ? "active" : ""} onClick={() => setProfileRedirect("payment")}><a className="link" href="https://www.simber.co.uk/profile/payment" >Payment Method</a></span>
                                        <span className={active === "settings" ? "active" : ""} onClick={() => setProfileRedirect("settings")}><a className="link" href="https://www.simber.co.uk/profile/settings" >Settings</a></span>
                                        <span onClick={() => setLogoutConfirmOpen(true)}>Log Out</span>
                                    </div>
                                }
                            </div>
                        )}                                
                    </Navbar.Collapse>
                    { expanded ||
                        <div className="login-mobil">
                        {props.token === "" ?
                            (
                                <Button onClick={() => setLogin(true)} className={(active === "login" || active === "register") ? "active login" : "login"}>
                                    <a className="link" href="https://www.simber.co.uk/login" >Sign In/ Sign Up</a>
                                </Button>
                            ) :
                            (
                                <div className="logout">
                                    <div className="closed" onClick={() => setAccountMenuOpen(!accountMenuOpen)}>
                                        <span>{props.name}</span>
                                        {accountMenuOpen || <img src={require("../images/pictograms/arrow.png")} />}
                                        {accountMenuOpen && <img className="open" src={require("../images/pictograms/arrow.png")} />}
                                    </div>                            
                                    {accountMenuOpen &&
                                        <div className="account">
                                            <span className={(active === "data"|| active === "bookings" || active === "payment" || active === "settings") ? "active" : ""} onClick={() => setProfileRedirect("data")}>Profile</span>
                                            <span onClick={() => setLogoutConfirmOpen(true)}>Log Out</span>
                                        </div>
                                    }
                                </div>
                            )
                        }
                        </div>
                    }
                    <Dialog
                        className="logout-modal"
                        open={logoutConfirmOpen}
                    >
                        <DialogContent className="logout-dialog-content">
                            <span>Are you sure you want to logout</span>
                            <span className="name">{props.name}?</span>
                            <Button className="logout-button" onClick={() => onLogoutClick()}>Logout</Button>
                        </DialogContent>
                        <img alt="Pictogram showing an 'X' representing the menu closing button." className="close-button" src={require("../images/pictograms/close_pictogram.png")} onClick={() => setLogoutConfirmOpen(false)}/>
                    </Dialog>
                </Navbar>
            </header>
        )
    }
}

const mapStateToProps = (state: RootState): IMappedProps => {
    return{ email: state.user.email, name: state.user.name, token: state.user.token}
};

const mapDispatchToProps = (dispatch: Dispatch<UserActionTypes & SummaryActionTypes>) =>
  bindActionCreators(
    {
      clearState,
      clearUserState
    },
    dispatch
);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeaderComponent)