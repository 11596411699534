import React from 'react';
import './ServiceTermsComponent.scss';

export default function ServiceTermsComponent() {
    return(
        <div className="service-terms-component">
            <h1>Service Terms and Conditions</h1>
            <h2>Our Payment Terms</h2>
            <ol>
                <li>Simber Chauffeur Services are responsible for both accepting the booking as well as the provision of the journey. We will use our reasonable endeavours to complete the performance of the services within the time agreed or as set out in the confirmation provided to the client upon booking.</li>                
            </ol>
                <h2>Our Payment Terms</h2>
            <ol>
                <li>Our payment terms for regular bookings unless otherwise agreed; all payments are due in full prior to any bookings.</li>
                <li>For all other bookings such as major social or corporate events including weddings, we require a 25% deposit with the remaining balance due 14 days upon completion of the booking.</li>
                <li>All prices quoted and charged are in pounds sterling (GBP) and exclusive of VAT unless otherwise stated.</li>
                <li>If you make payment by debit or credit card, the charge on your card statement will be shown as “SIMBER Chauffeur Services”.</li>
                <li>Any deviations to the original booking price, such as additional waiting time, extra hours, additional stops, additional mileage, and address changes for both pick-up and drop-off locations may be subject to additional charges.</li>
                <li>Car parking charges that are necessarily incurred in order to fulfil a booking will be charged at cost except where this cost is incorporated into our Airport Meet & Greet service charge.</li>
                <li>Airport transfers include 60 minutes of complimentary wait time from the flight landing time, 15 minutes of complimentary wait time on all other journeys. Additional waiting time if required will be charged in 15-minute intervals. Additional waiting time will be charged in line with the hourly rate for the vehicle type.</li>
                <li>Tolls, parking charges, congestion charges will be applied, where applicable. </li>
                <li>Unless specified at the time of booking, SIMBER may not automatically be able to accommodate changes to bookings including additional pick-ups or drop-offs for any journey, although every effort will be made by the chauffeur(s) to meet such requirements.</li>
                <li>If our chauffeur is asked to wait either at the beginning or during a journey, additional waiting time will be charged in line with the hourly rate for the vehicle type.</li>
                <li>Payment for account bookings is due within 14 days of the date of invoice.</li>
                <li>Any refund requests once approved will be processed to the client by way of an electronic transfer or if agreed by way of a credit note added on account for future trips. Should the client opt for an electronic transfer, the refund will be processed less deductions of transfer fees that are incurred for the transfer.</li>
                <h2>Public Holidays</h2>
                <li>Bookings carried out on Christmas Day, Boxing Day, New Year’s Eve from 18:00, and all-day New Year’s Day will be subject to an “unsocial hours” surcharge.</li>
                <li>Online quotations do not allow for bookings on public and bank holidays such as Christmas Day, Boxing Day, New Year’s Eve & New Years Day. We recommend our clients to Contact Us for a quotation. On Christmas Day, Boxing Day, New Year’s Eve from 18:00, and New Year’s day there will be a 100% surcharge on all journeys.</li>
                <h2>Our Cancellation Policy</h2>
                <li>Our bookings cancellation policy reflects the availability of the vehicles within the fleet. Upon acceptance of the Client booking, we reserve the availability of the vehicle and subsequently refuse all other bookings for that vehicle at that time & date.</li>
                <li>If a respective client wants to amend any details of the services you must tell us in writing as soon as possible or must edit their booking on the website by logging in. Any changes or any amendments can only be done with Simber and should not be discussed with the driver, they are responsible only for carrying out your journey We will use reasonable endeavours to make any required changes and additional costs will be included in the Fees and invoiced to you.</li>
                <li>Cancellation of any pre-booked chauffeur service service can only be done with Simber Chauffeur Services, by editing the booking online or by contacting us by email or telephone.</li>
                <li>If an airport transfer booking or inner city ride A-B (whitin London) is cancelled or amended within 12 hours of the requested pick-up time, SIMBER reserves the right to make a full charge for the journey. If a full day bookings or long distance booking is cancelled or amended within 24 hours of the requested pick-up time, SIMBER reserves the right to make a full charge for the journey</li>
                <li>In the event the Client or their respective passenger is late, the Client or their respective passenger must contact us and advise us of the same. Additional waiting time will be charged in line with the hourly rate for the vehicle type.</li>
                <li>A ride is considered a no-show if the Client, their passenger or guest, has not shown up without cancellation within 30 minutes after the agreed pickup time at the agreed pickup location. If a client, passenger, or guest does not show up, the ride must be paid for in full, whereas possible surcharges for waiting time do not apply.</li>
                <li>Cancellation policy for major corporate and social events:</li>
                <ul>
                    <li>If a Wedding Chauffeur Service is cancelled within 14 days from the start of the booking hire, the deposit taken will be non-refundable. If the Wedding Chauffeur Service is cancelled within 7 days from the start of the booking hire, the full booking hire amount will be charged. </li>
                    <li>If a major social or corporate event is cancelled within 14 days from the start of the booking hire, the deposit taken will be non-refundable. If within 7 days of a major social or corporate event the chauffeur service is cancelled by the client, the full booking amount will be charged. </li>
                </ul>
                <h2>Terms and Conditions Continued</h2>
                <li>SIMBER requests a minimum of 12 hours’ notice for any online bookings, if you would like to book a vehicle with less than 12 hours’ notice, please call our office on + (44) 203 983 5383. For all online bookings made between the hours of 21:00 and 06:00 that are also due to be carried out within the same time frame, we ask our clients to call our office on + (44) 203 983 5383 as we cannot confirm online bookings during unsocial hours.</li>
                <li>SIMBER will always endeavour to ensure vehicle(s) arrive at the time and place requested. We cannot however accept responsibility for delays caused by circumstances beyond our control, such as mechanical failure, traffic jams, traffic accidents, or extreme weather conditions. Nor do we accept responsibility for any consequential loss. </li>
                <li>The chauffeur(s) will travel by the most appropriate route on the day of the booking unless instructed otherwise by the Client, in which case Payment Clause 5. may apply.</li>
                <li>The chauffeur(s) will always drive at a sensible speed in accordance with the road, traffic, and legal speed limits.</li>
                <li>SIMBER shall be under no liability whatsoever to the customer for any indirect loss (including loss of profit) suffered by the Client, passenger, or customer arising out of the chauffeur service provided.</li>
                <li>Our passengers/clients are responsible for any damage caused to the interior or exterior of a vehicle on hire and will be billed accordingly for any repairs requested in order to reinstate a vehicle to working order. If special cleaning of any vehicle is required because it has been left in an unreasonable state by a passenger/client, we reserve the right to charge the cost of cleaning to that passenger/client.</li>
                <li>We reserve the right to refuse entry to our vehicles to anyone who is thought to be under the influence of alcohol or drugs and/or whose behaviour poses a threat to the safety of the driver, the vehicle, or other passengers within the vehicle. We further reserve the right to terminate any journey due to unreasonable or improper behavior by any passenger.</li>
                <li>SIMBER reserves the right to subcontract a vehicle or chauffeur/driver. SIMBER vehicle(s) and sub-contracted vehicles are fully insured for our passengers and third-party claims. However, passengers/clients’ properties are carried at their own risk and SIMBER will not be held responsible nor liable for any loss/damage to such property.</li>
                <li>Nothing contained in these terms and conditions affects the Client’s’ statutory rights.</li>
                <h2>Complaints Procedure</h2>
                <li>Simber Chauffeur Services are very proud of our high standards of customer service. However, in the event that we fail in meeting these standards, please do not hesitate to write to us or contact us by email clientcare@simber.co.uk</li>
            </ol>
        </div>
    )
}