import { Container } from '@material-ui/core';
import React from 'react';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import { AccordionComponent } from './AccordionComponent';
import './LongTermPage.scss'
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';

export default function LongTermPage(){

    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
          description: "Long Term Chauffeur Hire",
          url: "https://www.simber.co.uk/services/long-term-chauffeur"
        },
    ]
    
    return(
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="Enrich your London stay no matter how long it is by hiring a dedicated chauffeur service to take you to any location you need (in or out of London)." />
                <title>Chauffeur for a Day, Week, or Month</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/long-term-chauffeur" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="md" className="container">
                        <img onClick={() => window.scrollTo({top: 0, behavior: "smooth"})} className="top-img" src={require("../../images/pictograms/top_arrow.png")}/>
                        <div className="longterm-wrapper service-picture"></div>
                        <div className="content">
                            <h1>Hire a Chauffeur in London for a Day, Week, or Month</h1>
                            <p>When staying in London for an extended period, you may need to hire a chauffeur company. Whether it's a week or a month, Simber is here to handle this extended hire and be at your service - daily, a few times a day, or during a whole month. </p>
                            <h2>Seamless Chauffeur Service Regardless of How Long You Stay in London</h2>
                            <p>By hiring Simber, you'll enjoy a complete chauffeur service no matter your needs and time requirements. We will make sure you are stylishly accompanied during any business or touristic event during your visit to London, no matter how short or long.</p>
                            <p>Lets <a href="https://www.simber.co.uk/contact" rel="nofollow">discuss</a> details.</p>
                            <div className="picture-section">
                                <img alt="Buisnessman getting out of a car." src={require("../../images/servicepages/longterm_1.png")}/>
                                <div className="content">
                                    <h2>Chauffeur Service on an 'As Directed' Basis</h2>
                                    <p>Hire a chauffeur-driven service for a couple of hours, a few days, or even weeks on an 'As Directed' basis to make the most of your limited or extended stay in London.</p>
                                    <p>The long-term transportation services include driving to preferred locations such as meetings, family events, dinners, or sightseeing around London. </p>
                                </div>
                            </div>                    
                            <p>With Simber, all your transportation needs are in your control. We're ready to adapt to your schedule, staying flexible for as long as you require our services. </p>
                            <p>We're proud to take over full responsibility for your transport needs, regardless of how long you stay in London or whether you need to travel outside of the city. </p>
                            <p><button className="primary-button" onClick={() => window.location.href="https://www.simber.co.uk"}>Book Now</button></p>
                            <div className="devider"></div>
                            <h3>Hire a Chauffeur For a Day in London</h3>
                            <p>Your daily London visit can become an absolutely unforgettable experience with Simber's service. Our chauffeurs are discreet and reliable, offering tailored service to your specific daily needs. Choose a vehicle from our fleet to make your daily chauffeur hire a luxurious experience.</p>
                            <h3>Hire a Driver for a Week</h3>
                            <p>If you stay in London for a week and need a chauffeur each day, we're here to accommodate with a weekly hire. You can choose the same driver and vehicle for each day, or experiment with different cars for a more luxurious experience.</p>
                            <p><strong>Note:</strong> If you plan on hiring multiple drivers and vehicles, mention that in your booking request. If we can, we'll be happy to accommodate your requirements. Driver and vehicle availability depends on many factors, including whether they're already hired or on holiday leave.</p>
                            <h3>Hire a Driver for a Month</h3>
                            <p>A monthly driver while in London ensures you don't deal with traffic jams and parking limitations. Whether you're here for business or leisure, Simber can offer a monthly hire and reliable transportation for the whole period. </p>
                            <p>Monthly plans are customizable to your schedule, so feel free to contact us and get a quote on your hiring request.</p>
                            <h3>Hire a Driver for Your Extended Stay in London</h3>
                            <p>Sometimes you must stay for a few months or up to a year in London. Even though it's a long period, we can discuss the details and accommodate various needs like:</p>
                            <ul>
                                <li>Daily booking chauffeur service</li>
                                <li>Week-by-week hires</li>
                                <li>Transportation to other parts of the UK</li>
                                <li>Long-term contracts for hiring a chauffeur service</li>
                                <li>Combining a few of our existing services (for example, airport transfers and daily city tours)</li>
                            </ul>
                            <p>You can contact us even if you know you'll stay in London for a year or so so we can discuss the possibilities of an extended hire at your request.</p>
                            <h3>Who Can Use the Extended Period Hire?</h3>
                            <p>While not many visitors will need long-term hire for their extended stay in London, those who can benefit most are:</p>
                            <div className="row-space-around list">
                                <ul>
                                    <li>Tourists (solo travellers or those who travel with friends)</li>
                                    <li>Families who need a larger-capacity vehicle for an extended period</li>
                                    <li>Delegations who attend London events and ceremonies</li>                            
                                </ul>
                                <ul>
                                    <li>Executives and Business Representatives</li>
                                    <li>Celebrities who need to stay in London for longer</li>
                                    <li>Athletes who have a few competitions in an extended period</li>
                                    <li>Production teams who work on videos and movies</li>
                                </ul>
                            </div>
                            <p>Take a look at our <a href="https://www.simber.co.uk/vehicles" rel="nofollow">Fleet of Cars</a> to choose your ride and get the best long distance chauffeur service or a short term one.</p>
                            <div className="picture-section">
                                <img alt="Chauffeur opens the side of an MPV." src={require("../../images/servicepages/longterm_2.png")}/>
                                <div className="content">                            
                                    <p>Our Chauffeurs will always drive you to your destination in accordance with road standards and safety, using your preferred route along the way or the best route to avoid traffic. We aim to satisfy your needs, earn your trust and offer you the ultimate professional service in the industry.</p>
                                    <p>With Simber, it doesn't matter if you stay in London for a day, week, or more - we will always deliver a superior long-term service as you deserve.</p>
                                </div>
                            </div>
                            <div className="devider"></div>
                            <h2 className="faq-section-header">Long Term Chauffeur FAQ</h2>
                            <div className="faq-wrapper" itemScope itemType="https://schema.org/FAQPage">
                                <AccordionComponent
                                    question="Can I request a chauffeur I have previously used?" 
                                    answer="If you are satisfied with the level of transportation service London previously received, by all means, you may request the same chauffeur, as long as the chauffeur is available and has not been pre booked or already requested by another client."
                                />
                                <AccordionComponent
                                    question="I require multiple vehicles for my immediate family, staff and security, how do I make a chauffeur booking?" 
                                    answer="As these bookings can sometimes become complex please contact us by email, phone or by completing our contact form. Once this has been received, together with your vehicle requirements, number of passengers, pick up locations, length of stay etc., we will be able to put together a tailor made quote and complete your daily booking chauffeur services and help you find your family chauffeur."
                                />
                                <AccordionComponent
                                    question="When will I be provided with my chauffeur’s name and contact details?" 
                                    answer="Once you have completed your booking you will receive two mails: one as a confirmation email, and another prior to the start of your journey to confirm your driver/s contact details. When booking a long term car hire for a few days or weeks we will always make sure the same driver will be with you until the end of your transportation needs."
                                />
                                <AccordionComponent
                                    question="Can I request my chauffeur to drive business partners, friends and family as well as myself?" 
                                    answer={"On all bookings where the chauffeur is hired hourly, daily or weekly the chauffeur will be able to drive anyone you wish for him/her to do so. Your chauffeur is assigned on an As Directed basis and will listen and comply with your instructions for the hire term. Hire a london chauffeur and make your stay amazing."}
                                />
                                <AccordionComponent
                                    question="I’m traveling to the UK on holiday and scheduled to visit multiple cities. Can I book a chauffeur from Simber and combine long distance journeys with this service?" 
                                    answer={"Yes you can combine both transportation services London, many clients tend to do so for the same reasons when they hire a chauffeur. We are able to work with you and your itinerary, to coordinate multi-city visits, stops and sightseeing at the same time make sure that your assigned chauffeur's hotel is within close proximity in the event that he/she is required within short notice. Regardless of the length of your stay or any complex requirements please don't hesitate to contact us for a tailor made quote . You can hire a driver for long distance uk without any problems."}
                                />
                                <AccordionComponent
                                    question="How much notice should I provide Simber prior to booking a chauffeur? " 
                                    answer={"At Simber we have previously taken bookings with one hours notice, however to guarantee availability we would recommend that you make your booking as soon as you have confirmed your travel arrangements. If you also want to know how much a chauffeur driving service is, please contact us. A chauffeur for hire is the best you can find at Simber."}
                                />
                                <AccordionComponent
                                    question="Will my chauffeur drive responsibly and obey speed limits?" 
                                    answer={"Your chauffeur will always drive safely, responsibly and sensibly in accordance with road conditions, complying at all times with traffic and legal speed limits. Even if you book long distance transportation services, you never have to worry about your safety."}
                                />
                                <AccordionComponent
                                    question="Can I hire a chauffeur to drive my car?" 
                                    answer={"Contact us at Simber with your chauffeur long distance query or any other needs you have and we will get back to you and plan everything."}
                                />
                            </div>
                        </div>
                    </Container>
                </main>
                <FooterComponent/>
            </div>
        </div>
    )
}