import React, { useEffect } from 'react';
import './ExecutiveChauffeursTransfersPage.scss'
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';
import MetaTags from 'react-meta-tags';
import HeaderComponent from '../HeaderComponent';
import {Container} from '@material-ui/core'
import { AccordionComponent } from './AccordionComponent';
import FooterComponent from '../FooterComponent';
import $ from 'jquery';

function ExecutiveChauffeursTransfersPage() {

    useEffect(() => {
        $(document).ready(function () {

            $('.fadeHover').hover(
                function () {
                    $('.fadeHover').not(this).fadeTo('fast', 0.3);
                },
                function () {
                    $('.fadeHover').fadeTo('fast', 1);
                }
            );
        });
    })

    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
          description: "Executive Chauffeur Service",
          url: "https://www.simber.co.uk/services/executive-transfers"
        },
    ]

    return(
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="Executive chauffeur services for corporate C-suite roles and officials within a company or organization with immaculate vehicles and professional drivers." />
                <title>Executive Chauffeur Service | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/executive-transfers" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="md" className="container">
                        <img onClick={() => window.scrollTo({top: 0, behavior: "smooth"})} className="top-img" src={require("../../images/pictograms/top_arrow.png")}/>
                        <div className="corporate-wrapper service-picture"></div>
                        <div className="content">
                            <h1 className="details-header">London’s Best Executive Chauffeur Service</h1>
                            <p>Time is money, especially in the business and corporate world. </p>
                            <p>Our executive chauffeur services are made for busy executives and high-level roles in companies and organizations. No more battling traffic or navigating unfamiliar streets. Focus on what matters most - preparing for your next business success.</p>
                            <h2>Discretion Guaranteed: Professional Chauffeur Experience for Executives</h2>
                            <p>At Simber, we cater to corporate C-suite roles and officials with impeccable vehicles and skilled drivers.</p>
                            <p>Our trained chauffeurs are committed to providing exceptional service to any client. They anticipate your needs and provide complete privacy to handle some business meetings.</p>
                            <p>Experience luxury travel with our executive chauffeur hire in London.</p>
                            <p><button className="primary-button" onClick={() => window.location.href="https://www.simber.co.uk"}>Book Now</button></p>
                            <h3>Why Choose Simber's Executive Chauffeur Service in London?</h3>
                            <p>From great vehicles to building trustworthy partnerships, Simber ensures you arrive at your destination in elegance and sophistication.</p>
                            <p><strong>Why hire us?</strong></p>
                            <p>Based in the heart of London, our executive chauffeur service offers unparalleled convenience and accessibility, as well as:</p>
                            <ul>
                                <li><strong>Perfectly Clean Vehicles</strong> - A fleet of professionally maintained luxury cars for a comfortable and stylish ride.</li>
                                <li><strong>Professional Chauffeurs</strong> - Experienced, discreet, and highly-trained drivers dedicated to your comfort and privacy.</li>
                                <li><strong>Punctuality Guaranteed</strong> - Never miss an important meeting or appointment again. We get you where you need to be on time, every time.</li>
                                <li><strong>Easy and Intuitive Booking Process</strong> - Book your chauffeur service quickly and easily online or by phone.</li>
                                <li><strong>24/7 Availability</strong> - We are available for transfers at any time, day or night, on your request.</li>
                            </ul>
                            <p className='gold-text'><strong>Experience the Difference with Simber's Executive Chauffeur Services!</strong></p>
                            <p><a href="https://www.simber.co.uk/contact" rel="nofollow">Contact us</a> today and request personalized executive transfers for your upcoming business trip.</p>
                            <div className="devider"></div>
                            <h2>Hire London’s Most Experienced Executive Chauffeurs!</h2>
                            <p>Your safety and peace of mind are our top priorities. That's why we staff our service with experienced and highly trained drivers.</p>
                            <p>What sets us apart?</p>
                            <ul>
                                <li>We hire only the best chauffeurs who have the needed licenses and a clean background</li>
                                <li>We ensure you arrive anywhere in a safe and comfortable manner</li>
                                <li>We navigate London's streets professionally, so you can always arrive on time</li>
                            </ul>
                            <p><strong>Ride with confidence:</strong> Hire Simber for your next executive transfer.</p>
                            <div className="devider"></div>
                            <h2 className="faq-section-header">FAQs</h2>                    
                            <div className="faq-wrapper" itemScope itemType="https://schema.org/FAQPage">
                                <AccordionComponent
                                    question="What is an executive chauffeur?" 
                                    answer="The executive chauffeur is the person who drives a company executive (CEO, owner, C-suite employee) in a luxury car, prioritizing comfort and discretion and following the business timeline as requested."
                                />
                                <AccordionComponent 
                                    question="When do I need an executive chauffeur?" 
                                    answer="Hire an executive chauffeur when you need a stress-free and productive journey. This is an ideal option for airport transfers, important meetings, and stylish event arrivals."
                                />
                                <AccordionComponent 
                                    question="How do you hire the best executive chauffeur service company?" 
                                    answer="Always look for a company with well-maintained vehicles and experienced chauffeurs known for their punctuality and discretion. Read testimonials and reviews, and make the right choice."
                                />
                            </div>
                        </div>
                    </Container>
                </main>
                <FooterComponent/>
            </div>
        </div>

    )
}

export default ExecutiveChauffeursTransfersPage;