export interface PriceData {
  RentType: RentType;
  Origin: string;
  Destination: string;
  Date: Date;
  Hours?: number;
}
export enum RentType {
  Oneway = 0,
  ByTheHour = 1,
}
export enum RentStatus {
  Reserved,
  Payed,
  Canceled,
  Refunded
}
export interface PriceResponse {
    luxPrice: number;
    premPrice: number;
    sevenSeaterPrice: number;
}
export enum CarType {
  Premium = 1,
  SevenSeater = 2,
  Luxury = 3
}
