import React, { useEffect, useState } from 'react';
import HeaderComponent from '../HeaderComponent';
import FooterComponent from '../FooterComponent';
import "./VehiclesPage.scss"
import { Grid, Tooltip, Button } from '@material-ui/core';
import { ContactReasons } from '../../dtos/ContactData';
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';

export default function VehiclesPage() {

  const [offer, setOffer] = useState<ContactReasons | undefined>(undefined);
  const [init, setInit] = useState(true);

  useEffect(() => {
    if(init) {
      window.scrollTo({top:0})
      setInit(false);
    }
  })

  const scrollToContent = () => {
    var height = window.innerHeight

    window.scroll({top: height, behavior: 'smooth'});
  }

  function getOfferClick(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
      switch(e.currentTarget.id){
        case "offer2": {
          break;
        }
        case "offer1": {
          break;
        }
      }
  }

  const breadcrumbList: IBreadcrumb[] = [
    {
      description: "Vehicles",
      url: "https://www.simber.co.uk/vehicles"
    },
  ]

  if(offer){   
      return(
          <div className="wrapper">
              <HeaderComponent />
              <div className="form-wrapper">
                  <Button variant="outlined" className="back-button" onClick={() => setOffer(undefined)}>Back to prices</Button>
              </div>
              <FooterComponent />
          </div>
      )     
  }
  else {
  return (
      <div className="vehicle-page-wrapper">
          <MetaTags>
              <meta name="description" content="Experience London in the epitome of style and comfort with our exquisite fleet of chauffeured vehicles." />
              <title>Luxury Fleet Of Chauffeured Vehicles</title>
              <link rel="canonical" href="https://www.simber.co.uk/vehicles" />
          </MetaTags>
          <HeaderComponent />
          <main>
            <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
            <div className="vehicle-page-slogen">
              <h1 className="vehicles-header">Luxury Cars For Hire</h1>
              <span>Our fleet of ultra modern chauffeur driven vehicles are complimented by our professional, discreet and attentive chauffeurs. We look forward to having you enjoy your journey in ultimate satisfaction. </span>
              <img src={require("../../images/pictograms/arrow.png")} onClick={() => scrollToContent()}/>
            </div>
            <div className="vehicles-page-cards">
              <div className="vehicle-card">
                <div className="content">
                  <h2 className="vehicle-header">Luxury Vehicles For Hire</h2>
                  <h3 className="vehicle-sub-header">Mercedes S Class Hire</h3>
                  <p className="vehicle-text">The Mercedes S-Class symbolizes elegance and sophistication in the automotive world. The S-Class provides an excellent driving experience due to its exquisite appearance, cutting-edge technology, and superior comfort.</p>
                  <p className="vehicle-text">It's built to provide a calm haven for both drivers and passengers. The Mercedes S-Class is a harmonic blend of performance and richness, from the smooth performance of its powerful engines to the advanced safety features that define modern luxury. This way, it proves Mercedes-Benz's commitment to automotive excellence.</p>
                  <p><a href='https://www.simber.co.uk/vehicles/mercedes-s-class-chauffeur-hire'>Read more...</a></p>
                  <h3 className="vehicle-sub-header">Audi A8 Hire</h3>
                  <p className="vehicle-text">Audi A8 is a sophisticated and innovative luxury sedan with a sleek design featuring cutting-edge technology. It redefines everything we know about automotive excellence, providing a spectacular interior and comfortable ride.</p>
                  <p className="vehicle-text">From adaptive suspension to advanced driver assistance, Audi A8 shows us what it means to blend luxury and engineering mastery in one vehicle. That's why it's a great choice for a chauffeured experience.</p>
                  <h3 className="vehicle-sub-header">BMW 7 Series Hire</h3>
                  <p className="vehicle-text">A great choice for those who enjoy distinctive and elegant design, advanced technology, and exceptional performance. The BMW 7 series is a symbol of an excellently crafted interior by using premium materials.</p>
                  <p className="vehicle-text">Redefine the travel luxury with a vehicle that is committed to providing comfort and pleasure while travelling in the most exclusive way you can imagine.</p>
                  <p><a href='https://www.simber.co.uk/vehicles/bmw-i7-car-hire'>Read more...</a></p>
                  <div className="vehicle-stats">
                    <div className="vehicle-stat">
                      <img alt="Pictogram showing a walking passenger with their luggage." src={require("../../images/pictograms/passenger_pictogram.png")}/>
                      <span>Passengers: 3</span>
                    </div>
                    <div className="vehicle-stat">
                      <img alt="Pictogram showing a luggage bag." src={require("../../images/pictograms/luggage_pictogram.png")}/>
                      <span>Checked luggage: 1</span>
                    </div>
                    <div className="vehicle-stat">
                      <img alt="Pictogram showing a hand held luggage bag." src={require("../../images/pictograms/hand_luggage_pictogram.png")}/>
                      <span>Hand luggage: 2</span>
                    </div>
                  </div>
                </div>
                <img alt="Faded borderless image of a Mercedes S class, coming in focus on hover." className="vehicle-image" src={require("../../images/cars/mercedes_s_class.png")}/>
              </div>
              <div className="vehicle-card">
                <div className="content">
                  <h2 className="vehicle-header">Premium MPV</h2>
                  <h3 className="vehicle-sub-header">Mercedes V-Class</h3>
                  <p className="vehicle-text">The Mercedes V-Class is recognized as the most popular luxury people carrier, offering multiple seating configurations. A distinctively stylish exterior packed with advanced technology, it delivers ultimate safety and comfort for up to 7 passengers. Perfect for meetings on the road, tours, events and airport transfers as well as everything in between. </p>
                  <p><a href='https://www.simber.co.uk/vehicles/mercedes-v-class-chauffeur-hire'>Read more...</a></p>
                  <h3 className="vehicle-sub-header">Mercedes V-Class Hire</h3>
                  <p className="vehicle-text">Mercedes V-Class offers premium travel thanks to its interior and design. You can hire a chauffeur service for business trips, family outings, and special events. Whether for airport transfers, corporate events, or exploring the city, the Mercedes V-Class combines flexibility with sophistication for everyone, ensuring a secure journey for all occupants.</p>
                  <div className="vehicle-stats">
                    <div className="vehicle-stat">
                      <img alt="Pictogram showing a walking passenger with their luggage." src={require("../../images/pictograms/passenger_pictogram.png")}/>
                      <span>Passengers: 7</span>
                    </div>
                    <div className="vehicle-stat">
                      <img alt="Pictogram showing a luggage bag." src={require("../../images/pictograms/luggage_pictogram.png")}/>
                      <span>Checked luggage: 5</span>
                    </div>
                    <div className="vehicle-stat">
                      <img alt="Pictogram showing a hand held luggage bag." src={require("../../images/pictograms/hand_luggage_pictogram.png")}/>
                      <span>Hand luggage: 4</span>
                    </div>
                  </div>
                </div>
                <img alt="Faded borderless image of a Mercedes V class, coming in focus on hover." className="vehicle-image" src={require("../../images/cars/mercedes_v_class.png")}/>
              </div>
              <div className="vehicle-card">
                <div className="content">
                  <h2 className="vehicle-header">Range Rover</h2>
                  <p className="vehicle-text">Quintessentially British and at home in any surrounding while being elegant, discreet and stylish. The Range Rover Autobiography with extended rear legroom has become the vehicle of choice for the discerning passenger. Perfect for a shopping spree around London, a high-profile event, or even as your vehicle of choice to your private jet.</p>
                  <h3 className="vehicle-sub-header">Range Rover Autobiography Hire</h3>
                  <p className="vehicle-text">If you need reasons to hire a chauffeur driving Range Rover Autobiography edition, we have a few of them. It's one of the best vehicles featuring modern technologies but also off-road capabilities. That means you feel comfortable even w/hile travelling through challenging terrains. Still, you can hire this vehicle for urban explorations, as well as city excursions and several business occasions, enjoying the perfect and powerful performance on the streets.</p>
                  <span className="vehicle-inquiry" id="rover" onClick={(e) => getOfferClick(e)}>Enquire for a tailor made quote <img src={require("../../images/pictograms/arrow.png")}/></span>
                  <div className="vehicle-stats">
                    <div className="vehicle-stat">
                      <img alt="Pictogram showing a walking passenger with their luggage." src={require("../../images/pictograms/passenger_pictogram.png")}/>
                      <span>Passengers: 3</span>
                    </div>
                    <div className="vehicle-stat">
                      <img alt="Pictogram showing a luggage bag." src={require("../../images/pictograms/luggage_pictogram.png")}/>
                      <span>Checked luggage: 2</span>
                    </div>
                    <div className="vehicle-stat">
                      <img alt="Pictogram showing a hand held luggage bag." src={require("../../images/pictograms/hand_luggage_pictogram.png")}/>
                      <span>Hand luggage: 3</span>
                    </div>
                  </div>
                </div>
                <img alt="Faded borderless image of a Range Rover, coming in focus on hover." className="vehicle-image" src={require("../../images/cars/range_rover.png")}/>
              </div>
              <div className="vehicle-card">
                <div className="content">
                  <h2 className="vehicle-header">Rolls Royce Ghost</h2>
                  <p className="vehicle-text">The Rolls Royce Ghost is the ultimate luxury vehicle delivering at all times an unforgettable chauffeur driven experience. This vehicle is every bit as impressive on the exterior as well as the interior. Therefore, it is often the vehicle chosen for special occasions, weddings or lavish events. If you wish to make an impression and enjoy a ride that is totally unique, contact Simber for a tailor made quote specific to your requirements.</p>
                  <h3 className="vehicle-sub-header">Rolls Royce Ghost Hire</h3>
                  <p className="vehicle-text">Rolls Royce Ghost offers an unknown world of luxury and prestige, as well as automotive opulence, timeless design, and exceptional attention to detail. With its powerful engine and elegant interior, it's one of the safest vehicles to offer an exclusive ride to the passengers. So, whether it's for your wedding, honeymoon, or other event, you deserve to experience this excellence, making every moment worth the effort.</p>
                  <span className="vehicle-inquiry" id="royce" onClick={(e) => getOfferClick(e)}>Enquire for a tailor made quote <img src={require("../../images/pictograms/arrow.png")}/></span>
                  <div className="vehicle-stats">
                    <div className="vehicle-stat">
                      <img alt="Pictogram showing a walking passenger with their luggage." src={require("../../images/pictograms/passenger_pictogram.png")}/>
                      <span>Passengers: 3</span>
                    </div>
                    <div className="vehicle-stat">
                      <img alt="Pictogram showing a luggage bag." src={require("../../images/pictograms/luggage_pictogram.png")}/>
                      <span>Checked luggage: 1</span>
                    </div>
                    <div className="vehicle-stat">
                      <img alt="Pictogram showing a hand held luggage bag." src={require("../../images/pictograms/hand_luggage_pictogram.png")}/>
                      <span>Hand luggage: 2</span>
                    </div>
                  </div>
                </div>
                <img alt="Faded borderless image of a Rolls Royce Ghost, coming in focus on hover." className="vehicle-image" src={require("../../images/cars/rolls_royce.png")}/>
              </div>
              <div className="vehicle-card">
                <div className="content">
                  <h2 className="vehicle-header">8 to 16 seater Premium Minibus</h2>
                  <p className="vehicle-text">Simber can offer clients a number of Mercedes-Benz executive Minibuses which are available in various sizes; ranging from 8, 12 and 16 seaters. Perfect for transporting groups around the United Kingdom for corporate or social events, financial roadshows, sightseeing tours or special occasions. The Mercedes chauffeur driven minibuses are equipped with entertainment systems and comfortable leather seats making them a stylish and spacious option for your friends, family or business associates.</p>
                  <h3 className="vehicle-sub-header">8 Seater Minibus</h3>
                  <p className="vehicle-text">Elevate every group travel with exceptional spaciousness and comfort. These minibuses are designed for families or groups of friends but also for corporate teams on a trip together. With versatile seating configurations, everyone enjoys ample legroom and convenience.</p>
                  <p className="vehicle-text">From airport transfers to daily trips, the 8 Seater minibus offers a unique blend of style and practicality, ensuring a delightful journey. Every time you need safe and efficient group transportation, the 8 Seater minibus is here to meet all your requirements.</p>
                  <h3 className="vehicle-sub-header">16 Seater Minibus Hire</h3>
                  <p className="vehicle-text">For even larger groups, we recommend the 16 Seater minibuses designed to provide enough space and comfort for every passenger. You can use it for corporate events because the advanced features and modern amenities provide convenience and safety for the whole group of passengers. Reach your preferred destination in style, even when you travel with friends and family, or maybe corporate partners or some special group of people by your side.</p>
                  <span className="vehicle-inquiry" id="minibus" onClick={(e) => getOfferClick(e)}>Enquire for a tailor made quote <img src={require("../../images/pictograms/arrow.png")}/></span>
                  <div className="vehicle-stats">
                    <div className="vehicle-stat">
                      <img alt="Pictogram showing a walking passenger with their luggage." src={require("../../images/pictograms/passenger_pictogram.png")}/>
                      <span>Passengers: 10-16</span>
                    </div>
                  </div>
                </div>
                <img  alt="Faded borderless image of a Mercedes minibus, coming in focus on hover." className="vehicle-image" src={require("../../images/cars/minibus.png")}/>
              </div>
            </div>
          </main>
          <FooterComponent />
      </div>
  )
  }
}