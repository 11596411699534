import './CorporateChauffeursPage.scss'
import {Button, Container} from '@material-ui/core'
import React, { useEffect } from 'react';
import HeaderComponent from '../HeaderComponent'
import { ContactReasons } from '../../dtos/ContactData';
import FooterComponent from '../FooterComponent';
import { AccordionComponent } from './AccordionComponent';
import $ from 'jquery';
import MetaTags from 'react-meta-tags';
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';

function CorporateChauffeursPage() {

    useEffect(() => {
        $(document).ready(function () {

            $('.fadeHover').hover(
                function () {
                    $('.fadeHover').not(this).fadeTo('fast', 0.3);
                },
                function () {
                    $('.fadeHover').fadeTo('fast', 1);
                }
            );
        });
    })

    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
          description: "Corporate Chauffeur Service",
          url: "https://www.simber.co.uk/services/corporate-chauffeur"
        },
    ]

    return(
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="Immerse yourself in the unparalleled luxury of our corporate chauffeur services in London, and let Simber redefine your expectations of corporate transportation." />
                <title>Corporate Chauffeur Service | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/corporate-chauffeur" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="md" className="container">
                        <img onClick={() => window.scrollTo({top: 0, behavior: "smooth"})} className="top-img" src={require("../../images/pictograms/top_arrow.png")}/>
                        <div className="corporate-wrapper service-picture"></div>
                        <div className="content">
                            <h1 className="details-header">London’s Best Corporate Chauffeur Service</h1>
                            <p>Business meetings, corporate special events, and individual clients transportation? Not a problem! Simber is ready to accommodate all your specific corporate transfer requirements, offering a wide array of services for business occasions.</p>
                            <h2>Corporate Chauffeurs for Residential & International Business Travellers</h2>
                            <p>Experience luxury, comfort, and safety with our corporate chauffeur services in London and throughout the UK. Our licensed, professional chauffeurs ensure your peace of mind for a stress-free business trip, no matter your company's role.</p>
                            <p><button className="primary-button" onClick={() => window.location.href="https://www.simber.co.uk"}>Book Now</button></p>
                            <div className="picture-section big">
                                <img alt="A man and a women waiting for their corporate booked chauffeur." className="" src={require("../../images/servicepages/corporate_1.png")}/>
                                <div className="content">
                                    <h3> Chauffeurs for Business Travellers </h3>
                                    <p>Whether you're on a business trip in London or across the UK, we're here to provide a chauffeur-driven business experience, taking you to meetings, conferences, or financial roadshows. Our corporate chauffeur service is here to cater to your logistical needs with a vast vehicle selection.</p>
                                    <p>You only need to enjoy the pleasant transportation experience while staying focused on your business matters.</p>
                                </div>
                            </div>
                            <div className="devider"></div>
                            <h3>Chauffeurs for Corporate Guests</h3>
                            <p>When hosting business visitors in London, Simber handles their VIP transportation needs. Whether it's a day trip or a multi-day stay, our chauffeurs ensure a seamless experience from airport arrival to departure. Our all-inclusive corporate services offer transfers from and to meetings and conferences while you impress the business guests, leaving a lasting impression.</p>
                            <div className="devider"></div>
                            <img alt="Inside of a large MPV ideal for corporate uses." className="large-image" src={require("../../images/servicepages/corporate_2.png")}/>
                            <h3>Corporate Business Trips </h3>
                            <p>Company managers who need chauffeur services to handle transportation can always reach out to us and get a complete service. We specialize in corporate services for meetings, roadshows, conferences, PR events, airport transfers, and more.</p>
                            <p>A partnership with Simber means an unmatched corporate event, discretion, and always accommodating your staff, guests, and business partners.</p>
                            <p>Our fleet features vehicles that can accommodate passengers on your request - from exclusive Sedans to on-demand minibuses. Let us help you organize this business trip with you, so you can shine as a host.</p>
                            <p>Find out more about how to partner with us <a href="https://www.simber.co.uk/contact" rel="nofollow">here.</a></p>
                            <h2>A Wide Range of Vehicles for Corporate Transfers</h2>
                            <p>Our chauffeur driven vehicles - including the stylish Mercedes E-Class and versatile Mercedes V-Class - are perfect for conferences. However, the elegant and comfortable Mercedes S-Class redefines luxury and aesthetics in corporate travelling. To view the full range of London VIP chauffeur driven vehicles please visit the <a href="https://www.simber.co.uk/vehicles" rel="nofollow">Vehicles Page</a></p>
                            <p>All vehicles are disinfected before and after each trip, ensuring your health and safety and a contactless journey are guaranteed. As you deal with the stress of your everyday job and tackle unexpected challenges, Simber offers you the space needed to relax in between meetings or before and after attendance of conferences.</p>
                            <h2>Corporate Transfers and Services for Business Meetings</h2>
                            <p>Hire a corporate chauffeur service for punctual arrivals, seamless travelling, and adapting to the local traffic laws and regulations. VIP chauffeurs for corporate purposes not only enhance your efficiency but boost your image, too.</p>
                            <p>Simber's corporate chauffeur services are tailor-made to your requirements and unique needs. With the extensive vehicle fleet, we can manage multiple requests from the same company, including:</p>
                            <ul>
                                <li>Bespoke services when it comes to business meetings (taking you there and picking you up)</li>
                                <li>Chauffeur services for corporate events of any kind</li>
                                <li>Long-distance travelling for business purposes (for example, from London to any other UK destination, as well as taking you back to London)</li>
                                <li>Transfers from and to airports or bus/train stations across London and the UK</li>
                                <li>Other tailor-made offers on your request</li>
                            </ul>
                            <p>With Simber by your side, you get the best from London corporate chauffeur services. All you have to do is enjoy the ride, handle some business calls, and never think about dealing with local traffic and laws alone.</p>
                            <p><button className="primary-button" onClick={() => window.location.href="https://www.simber.co.uk"}>Book Now</button></p>
                            <div className="devider"></div>
                            <h2 className="faq-section-header">Corporate Chauffeur FAQ</h2>                    
                            <div className="faq-wrapper" itemScope itemType="https://schema.org/FAQPage">
                                <AccordionComponent
                                    question="How do I book a corporate chauffeur service?" 
                                    answer="You can book a corporate chauffeur London by filling out the automated booking form on the homepage. Simply, enter your pick up location, drop off location, choose a vehicle depending on your requirements and proceed to payment. Once this is done, you will receive a confirmation email, and then, prior to your journey, another mail will follow to confirm the driver’s contact details. For hourly limo service London and more info, you can always call us on the phone."
                                />
                                <AccordionComponent 
                                    question="We travel on a regular basis, as opposed to constant payments do you offer memberships or subscriptions?" 
                                    answer="At Simber we offer packages which are tailored to your travel needs, these packages can include a combination of airport transfers, inner city rides, as well as hourly chauffeur hire and a chauffeur corporate hire.. We are also able to set up a business account for your chauffeured services for business and process all of your trips monthly keeping all trips under one roof."
                                />
                                <AccordionComponent 
                                    question="How far in advance do I need to book?" 
                                    answer="At Simber which is a chauffeur service company, we have previously worked with telephone bookings with one-hour- notice. However, to guarantee availability we recommend that you make your booking as soon as you have confirmed your corporate VIP meetings / itinerary."
                                />
                                <AccordionComponent 
                                    question="Will the chauffeur respect my privacy and confidentiality? " 
                                    answer="All of the chauffeurs at our corporate chauffeur service London have put their chauffeur sign on non disclosure agreements, therefore, any conversations within the vehicle, stay in the vehicle."
                                />                        
                                <AccordionComponent 
                                    question="What is the most popular chauffeur car for corporate clients?" 
                                    answer="The Mercedes S-Class is the most popular choice, the Mercedes E-Class saloon comes in a favourable second place, especially for short city trips. The Mercedes V-Class is the choice for meeting on the go, roadshows and conferences."
                                />
                                <AccordionComponent 
                                    question="What is an executive business chauffeur service London?" 
                                    answer="UK VIP chauffeur services are entrusted to look after a diverse corporate clientele, delivering our continued exacting standards in discretion, precision and reliability and provide the executive chauffeur services. We also offer international chauffeur service."
                                />
                                <AccordionComponent 
                                    question="Will I be notified when my car arrives?" 
                                    answer="Our executive chauffeur service London will notify you as soon as your vehicle arrives at the agreed pick up point, which will be about 10-15 minutes prior to your booking."
                                />
                                <AccordionComponent 
                                    question="What is the difference between a taxi driver and a chauffeur?" 
                                    answer="Business chauffeur service provides first rate transportation services all year-round, at the same time they prioritise customer service and promote our professionally chauffeured services. Our chauffeur driven service wants what is best for our clients and follows the necessary steps to make travel an enjoyable experience. Also, our corporate chauffeured cars give all the comfort for a great ride."
                                />
                                <AccordionComponent 
                                    question="Will the chauffeur talk to our clients or passengers?" 
                                    answer="At our chauffeuring company we have chauffeurs that are polite, professional and discreet. If the passenger or client would like to talk or ask questions then the chauffeur will happily engage and respond. We provide executive chauffeuring for all your needs with a business chauffeur London. Before booking online, make sure you have done a good chauffeur service reading of the things provided to make sure you have it all clear."
                                />
                                <AccordionComponent 
                                    question="Will the chauffeur drive in excess of any speed limits?" 
                                    answer="Your chauffeur will always drive safely, responsibly and sensibly in accordance with road conditions, complying at all times with traffic and legal speed limits, so you won’t have to worry in advance when you purchase your corporate event chauffeur hire."
                                />
                            </div>
                        </div>
                    </Container>
                </main>
                <FooterComponent/>
            </div>
        </div>

    )
}

export default CorporateChauffeursPage;