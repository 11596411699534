import { Container } from '@material-ui/core';
import React from 'react';
import StructuredBreadcrumb, { IBreadcrumb } from '../../BreadCrumbs';
import FooterComponent from '../../FooterComponent';
import HeaderComponent from '../../HeaderComponent';
import MetaTags from 'react-meta-tags';
import './AirportPageGatwick.scss'

export default function AirportPageGatwick() {
    
    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
          description: "Airport Transfers",
          url: "https://www.simber.co.uk/services/airport-transfers"
        },
        {
          description: "Luxury Gatwick Airport Transfers",
          url: "https://www.simber.co.uk/services/airport-transfers/gatwick"
        },
    ]

    return (
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="Enjoy the seamless and personalized ride specially tailored for every client who needs luxury Gatwick airport transfers. Book your exclusive experience now." />
                <title>Luxury Gatwick Airport Transfers | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/airport-transfers/gatwick" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="md" className="container">
                        <div className="gatwick-wrapper service-picture"></div>
                        <div className="content">
                            <h1>Gatwick Airport Transfers: Personalized Pick-ups and Drop-offs</h1>
                            <p>Our top Gatwick airport transfers are designed to make your journey perfect. From the moment you step off the plane, you'll be greeted by your chauffeur, who is responsible for providing the highest level of service. It's the same when you need to go to Gatwick airport.</p>
                            <p>It's up to you to sit back, relax, and enjoy the opulence, as we've already taken care of every detail that makes your trip comfortable and stylish.</p>
                            <div className="devider"></div>
                            <h2>Gatwick Airport Private Transfers to Indulge in Unparalleled Luxury</h2>
                            <p>Are you ready to step into a world of unparalleled luxury using our Gatwick airport transfers? Designed only for you, our service offers a level of exclusivity that surely goes beyond expectations. From the moment you arrive, your assigned driver will help you with the luggage, ensuring you're comfortable all the time.</p>
                            <p>It's time to experience the convenience of the seamless and personalized journey tailored to your preferences. We are committed to delivering that for you, making your trip nothing less than extraordinary.</p>
                            <h3>The Ultimate VIP Experience to Arrive in Style</h3>
                            <p>One thing is for sure! You must get ready to <a href='https://www.simber.co.uk/services/airport-transfers'>receive VIP airport transfers</a> from us. Our team of experienced chauffeurs is well-versed in providing the highest level of service, ensuring your every need is met.</p>
                            <p>It's on you to let us greet you with a warm welcome and provide a smooth and comfortable ride to your preferred destination. Our goal is to make your arrival at Gatwick truly unforgettable, as well as the ride to your hotel, accommodation, or home.</p>
                            <div className='devider'></div>
                            <h2>Transfers to Gatwick Airport: Convenient and Luxurious Transportation</h2>
                            <p>Whether you're heading to catch a flight or welcome someone you love, we are here to provide the most convenient luxurious transportation. The result is a stress-free journey, no matter if you travel alone or with a friend, partner, or a family member.</p>
                            <p>Additionally, our fleet of luxury vehicles offers the utmost comfort and sophistication, ensuring that your transfer is a delightful experience from start to finish.</p>
                            <h3>Effortless Journey with Transfers to Gatwick</h3>
                            <p>Why choose Simber? Because with us, you leave the hassles of heavy traffic and parking behind you. Also, we can plan the whole ride for you, considering traffic conditions and flight schedules.</p>
                            <p>With our commitment to these details, you will surely receive a smooth ride to Gatwick. You only need to focus on the one thing that truly matters - enjoying your trip.</p>
                            <div className='devider'></div>
                            <h2>Airport Transfers from Gatwick: First-Class Service to Your Destination</h2>
                            <p>Our professional chauffeurs are here if you need to travel to or from Gatwick. You know how it's done - you sit back and relax, and we take care of navigating the roads and dealing with the traffic.</p>
                            <p>Our commitment to excellence means you can expect a smooth and comfortable journey, arriving at your destination feeling refreshed and ready to embark on your next adventure.</p>
                            <h3>Unwind and Relax with Our Transfers from Gatwick</h3>
                            <p>When travelling, you deserve the best possible experience. Flights can be tiring, so the trip from the airport to home shouldn't be an additional challenge for you.</p>
                            <p>Instead, you can let us deal with your luggage and guide you to your elegant vehicle. Experience the epitome of luxury and relaxation with our airport transfers from Gatwick.</p>
                        </div>
                    </Container>
                </main>
                <FooterComponent/>
            </div>
        </div>
    );
}