import { Container } from '@material-ui/core';
import React from 'react';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';
import { AccordionComponent } from './AccordionComponent';
import MetaTags from 'react-meta-tags';
import './AirportTransfersPage.scss'
import StructuredBreadcrumb, { IBreadcrumb } from '../BreadCrumbs';

export default function AirportTransfersPage(){
    
    const breadcrumbList: IBreadcrumb[] = [
        {
            description: "Services",
            url: "https://www.simber.co.uk/services",
            dontNavigate: true
        },
        {
          description: "Airport Transfers",
          url: "https://www.simber.co.uk/services/airport-transfers"
        },
    ]

    return (
        <div className="service-background-wrapper">
            <MetaTags>
                <meta name="description" content="Discover the epitome of luxury airport transfers and make your reservation today. Trust us to provide a seamless, reliable, and unforgettable journey." />
                <title>Airport Transfers in Complete Luxury and Comfort | Simber</title>
                <link rel="canonical" href="https://www.simber.co.uk/services/airport-transfers" />
            </MetaTags>
            <div className="service-page-wrapper">
                <div className="content-wrapper"></div>
                <HeaderComponent/>
                <main>
                    <StructuredBreadcrumb breadcrumbList={breadcrumbList} />
                    <Container maxWidth="md" className="container">
                        <div className="airport-wrapper service-picture"></div>
                        <div className="content">
                            <h1>Luxury Airport Transfers</h1>
                            <h2>Luxury Airport Transfers London</h2>
                            <p>Looking for the most convenient London airport car service for seamless travelling? Simber offers you hassle-free VIP transfers with VIP airport transfer service - provided by a professional driver - ensuring your airport arrival or departure goes as smoothly as possible.</p>
                            <p>As a luxury airport transfer service in London, we offer pick-ups and drop-offs from and to all major London or UK airports. Whether you arrive or depart at London City Airport, Gatwick, Heathrow, Stansted, Biggin Hill, Farnborough or London Luton Airport, Simber will handle the most stressful part of your journey.</p>
                            <p>Book your Airport transfer on <a href="https://www.simber.co.uk">Simber</a></p>
                            <div className="devider"></div>
                            <h3>UK London Airport Luxury Drop Off and Pick Up</h3>
                            <p>Your luxury travel experience starts once you arrive at some of the anticipated UK airports. Whether you choose a sedan or a luxury limo, you can expect these services:</p>
                            <ul>
                                <li>
                                    Personal airport transfer by your Chauffeur at the airport terminal and transfer straight to the car, including
                                    <ul>
                                        <li className="sub-list-item">Heathrow Chauffeur Airport Transfers</li>
                                        <li className="sub-list-item">London City Airport Transfers</li>
                                        <li className="sub-list-item">Luton Airport Transfers</li>
                                        <li className="sub-list-item">Gatwick Chauffeur Airport Transfers</li>
                                        <li className="sub-list-item">Stansted Airport Transfers</li>
                                        <li className="sub-list-item">Biggin Hill Airport Transfers</li>
                                        <li className="sub-list-item">Farnborough Airport Transfers</li>
                                    </ul>
                                </li>
                                <li>Assisting you with luggage or equipment from the terminal to the vehicle.</li>
                                <li>Set of essentials in the car (water, mints, tissues, antibacterial gel, WiFi)</li>
                                <li>Smooth contactless ride</li>
                                <li>One hour complimentary wait </li>
                                <li>Fixed pricing, including tolls and parking fees for one hour</li>
                                <li>Flight monitoring to ensure you are met on time, regardless if your flight is delayed or early. </li>
                            </ul>
                            <div className="picture-section">
                                <img alt="Chauffeur placing passengers bag in the trunk at the airport while providing luxury airport transfer in London." src={require("../../images/servicepages/airport_1.png")}/>
                                <div className="content">
                                <h3>Private Jet Airport London Transfer Services </h3>
                                <p>We offer Meet and Greet London services directly from the plane for clients arriving with private jets at Farnborough Airport, London Biggin Hill, at Harrods Aviation or Signature Flight Support. With Simber, the minute you land you will receive friendly greetings and an exceptional service to remember.</p>
                                <p>We'll also organise transfers to your hotel, business meetings or any other events in premium luxury and comfort. Plus, you will get a complimentary wait time for up to one hour, and flight monitoring just to make sure that you are picked up on time.</p>
                                </div>
                            </div>
                            <div className="devider"></div>
                            <h3>Departing from a London Airport </h3>
                            <p>Your luxury travel experience doesn't stop even when leaving London. Our London City airport transfer services are dedicated to the client's comfort even when travelling home, ensuring everything is taken care of. That's why we provide a variety of luxury transportation options tailored to your needs, like:</p>
                            <ul>
                                <li>Picking you up from your prefered location </li>
                                <li>Assisting you with your luggage or equipment</li>
                                <li>Additional stops to pick up friends, family, or colleagues (if you need this service, please specify the details in the booking form)</li>
                                <li>Discreet, professional chauffeur driven service to your airport terminal of choice </li>
                                <li>
                                    While getting to the airport you can enjoy a seamless ride in a:
                                    <ul>
                                        <li className="sub-list-item">Luxury Mercedes S-Class</li>
                                        <li className="sub-list-item">Spacious Mercedes V-Class</li>
                                        <li className="sub-list-item">Elegant Range Rover Autobiography</li>
                                        <li className="sub-list-item">Extravagant Rolls Royce Ghost </li>
                                        <li className="sub-list-item">8 to 16 seater Mercedes-Benz Minibuses</li>
                                    </ul>
                                </li>
                                <li>Set of essentials in the car (water, mints, tissues, antibacterial gel, WiFi)</li>
                                <li>Smooth contactless ride</li>
                            </ul>
                            <p><button className="primary-button" onClick={() => window.location.href="https://www.simber.co.uk"}>Book Now</button></p>
                            <div className="devider"></div>
                            <h2>Luxury Airport Transport Vehicles</h2>
                            <p>The advantage of hiring a chauffeur company to pick you up or drop you off at some London airports comes with various benefits. The extensive choice of exclusive and eco-friendly vehicles is one of them.</p>
                            <p>While it seems like it's always a luxury airport limousine chauffeur service, we can ensure you that we also offer the following:
                                <ul>
                                    <li>Beautiful and comfortable sedans</li>
                                    <li>Elegant electric and hybrid cars</li>
                                    <li>Premium MPV carriers</li>
                                    <li>Luxury cars with off-road capabilities</li>
                                    <li>Exclusive Minibuses for larger groups</li>
                                </ul>
                            </p>
                            
                            <div className="picture-section">
                                <img alt="Luxury airport transfers in London Your Chauffeur placing multiple bags in the back of an MPV." src={require("../../images/servicepages/airport_2.png")}/>
                                <div className='content'>
                                    <p>Each of these vehicle types comes with a beautiful interior, comfortable seats, and ambience lighting. </p>
                                    <p>Our vehicles take you on a ride from any London airport to a specific London location or even to other UK cities on your request.</p>
                                    <p><a href="https://www.simber.co.uk/contact" rel="nofollow">Contact us</a> with your request and we will organise additional services for you, your family or clients. </p>
                                </div>
                            </div>
                            <h2>Luxury Airport Transfer Prices</h2>
                            <p>The luxury journey from the airport to any spot by choice depends on various factors. While we can't have a transparent list of the service prices, we can share what affects the price. That way, you can estimate what it takes to book an airport transfer in London.</p>
                            <p>
                                <ul>
                                    <li>The specific airport in London</li>
                                    <li>The distance between the airport and the drop-off location</li>
                                    <li>Whether you hire us for a few days or a week</li>
                                    <li>The need for additional transfers while in London</li>
                                    <li>Special requests that aren't included in the basic package</li>
                                    <li>The empty mileage if we need to pick you up from a city other than London</li>
                                    <li>Long-distance requests for UK-wide destinations</li>
                                    <li>Long-distance trips outside the UK, like France</li>
                                </ul>
                            </p>
                            <p>Anyway, you can use the form on our homepage to book the trip or talk to us directly for specific arrangements and complex trip requests so we can accommodate you. </p>
                            <p><button className="primary-button" onClick={() => window.location.href="https://www.simber.co.uk"}>Book Now</button></p>
                            <div className="devider"></div>
                            <h2 className="faq-section-header">London Airport Transfers FAQs</h2>
                            <div className="faq-wrapper" itemScope itemType="https://schema.org/FAQPage">
                                <AccordionComponent
                                    question="How do I book an airport transfer and what information is required from me?" 
                                    answer="You can book a luxury airport transfer by filling out the automated booking form on the home page, simply enter your pick up location, drop off location, choose a vehicle depending on your requirements and proceed to payment. Once this is done you will receive a confirmation email confirming your trip, another email will follow prior to your journey to confirm the driver's contact details. Alternatively if you wish, you can call us to book or contact us via the contact form on the Contact us page with your requirements. Please make sure to include your flight number as well as the lead passengers name."
                                />
                                <AccordionComponent 
                                    question="I’m not the one travelling, can I book an airport transfer on behalf of someone else?" 
                                    answer="Absolutely, all of our clients can book on behalf of family, friends, clients or business associates. If you're not the passenger simply add the lead passengers details & their contact number in the booking form. Once they arrive at the airport the chauffeur will greet them with a nameboard."
                                />
                                <AccordionComponent
                                    question="Which vehicles can I choose from and how much luggage can I fit?"
                                    answer="Clients can choose from a number of luxury car transfers’ vehicles which are listed on the Vehicles page including the amount of luggage each vehicle can fit, The vehicles include the executive Mercedes E Class, Luxury Mercedes S-Class and the extremely comfortable Mercedes V-Class. On request Simber can arrange a Range Rover Vogue, please contact us to request this vehicle."
                                />
                                <AccordionComponent
                                    question="Where will I meet the chauffeur at the airport?"
                                    answer="One of the main benefits from the luxury car airport transfer is that your chauffeur will always wait for you in the arrivals hall upon landing with a name board. If you prefer being picked up from a specific meeting location within the terminal, please add this location or inform us by contacting us prior to your flight."
                                />
                                <AccordionComponent
                                    question="What if my flight is landing early or late, will my chauffeur be there? "
                                    answer="At Simber, we always track our clients' flight; therefore, regardless of whether you are landing early or are delayed we will always have the London airport chauffeur as well as an immaculate vehicle waiting for you. The best thing is that whether you need a Heathrow airport chauffeur transfers, airport transfer Heathrow to London, or any other, it doesn’ matter, the chauffeur will wait for you."
                                />
                                <AccordionComponent
                                    question="My flight has been cancelled, how do I inform you best?"
                                    answer="Once we confirm a booking we allocate a chauffeur and vehicle for that particular journey, this cancels out any other bookings that chauffeur / vehicle would receive. We would therefore recommend that you either email us or call us as soon as you know your flight has been cancelled. Naturally, we would be delighted to reschedule your bookings with Simber."
                                />
                                <AccordionComponent
                                    question="Are tolls and parking included in the fare? "
                                    answer="All of our airport transfers include 1 hour of complimentary wait time, this includes tolls and parking charges. We do understand however that airports can sometimes be busier than usual and as our client it may take you longer than one hour to pass customs & baggage claim, therefore any additional costs incurred such as parking will be passed on at cost, additional waiting time will be charged in 15 minute intervals. Therefore, we cover a large field of expenses, and that’s why we provide the best airport transfers London."
                                />
                                <AccordionComponent
                                    question="Do you provide car seats for children?"
                                    answer="We provide child seats for children, these include baby seats, child seats and booster seats. Prior to confirming your booking please do add your child's age or the type of child seat required."
                                />
                                <AccordionComponent
                                    question="Do young children count as passengers? "
                                    answer="Regardless of age, the London chauffeur airport transfers count everyone in the vehicle as a passenger."
                                />
                                <AccordionComponent
                                    question="Can I book the same driver for other services? "
                                    answer="If you are satisfied with the level of executive airport transfers London service received, by all means, you may request the same chauffeur for other services, as long as the chauffeur is available and has not been pre booked or already requested by another client."
                                />
                            </div>
                        </div>
                    </Container>
                </main>
                <FooterComponent/>
            </div>
        </div>
    )
}