import React from "react";
import { Container } from "@material-ui/core";
import { NavLink } from "react-router-dom";
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import MetaTags from 'react-meta-tags';
import "./PaymentSuccessPage.scss"

export function PaymentSuccessPage() {
  return (
    <div className="wrapper">
      <MetaTags>
          <meta name="description" content="Simber Chauffeur Services Payment Successfull page" />
          <title>Payment Successfull | Simber</title>
      </MetaTags>
      <HeaderComponent />
      <main>
        <Container className="payment-success-wrapper" maxWidth="md">
          <h2>Payment Successful!</h2>
          <p>
            Thank you for booking with Simber Chauffeur Services!
          </p>
          <div>You will recieve an email with the details of your booking shortly, this will be sent to the email address you have provided.</div>
          <NavLink to="/" className="continue-button">
              Return to Homepage
          </NavLink>
        </Container>
      </main>
      <FooterComponent />
    </div>
  );
}
