import { Alert, Button, Container, CssBaseline, Snackbar, TextField, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { axiosInstance } from '../config/Axios.config';
import UserLoginDto from '../dtos/UserLoginDto';
import FooterComponent from './FooterComponent';
import HeaderComponent from './HeaderComponent';
import { saveToken, UserActionTypes } from "../redux/actions";
import { bindActionCreators, Dispatch } from 'redux';
import { Redirect } from 'react-router';
import MetaTags from 'react-meta-tags';

function EmailConfirmPage() { 
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [open, setOpen] = useState(false);
    const [errorMsg, setError] = useState("");
    const [redirect, setRedirect] = useState(false);

    const dispatchH = useDispatch();

    const tryLogin = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        var data: UserLoginDto = {
          Email: email,
          Password: pass
        }
        axiosInstance.post("user/confirmEmail" + window.location.search, data)
          .then(response => {
            axiosInstance.defaults.headers["Authorization"] = "Bearer " + response.data.token
            dispatchH(saveToken(response.data));
            setRedirect(true);
          })
          .catch(error => {
            if (error.response?.status === 404) {
              setError("Unknown error occured");
            }
            else {
              if(error.status === 419) {
                localStorage.clear();
                window.location.href = "/";
                return;
              }
              if (error.response?.data !== undefined) {
                setError(error.response.data.message);
              }
              else {
                setError("Cannot reach server");
              }
            }
            setOpen(true);
        })
    }
    
    if (redirect) {
        return (<Redirect push to="/" />)
    }
    else {
        return (
            <div className="wrapper">
              <MetaTags>
                  <meta name="description" content="Simber Chauffeur Services Email Confirmation page." />
                  <title>Email Confirm | Simber</title>
              </MetaTags>
                <HeaderComponent />
                <main>
                  <Container component="main" maxWidth="sm">
                  <CssBaseline />
                  <div className="login-card">
                      <Typography className="sign-in-header" component="h1" variant="h5">
                          Sign in to confirm your email
                      </Typography>
                      <form noValidate>
                      <TextField
                          variant="filled"
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          label="Email Address"
                          name="email"
                          autoComplete="email"
                          autoFocus
                          value={email}
                          onChange={(e) => { setEmail(e.currentTarget.value) }}
                      />
                      <TextField
                          variant="filled"
                          margin="normal"
                          required
                          fullWidth
                          name="password"
                          label="Password"
                          type="password"
                          id="password"
                          autoComplete="current-password"
                          value={pass}
                          onChange={(e) => { setPass(e.currentTarget.value) }}
                      />
                      <Button
                          type="submit"
                          fullWidth
                          variant="contained"
                          onClick={(e) => tryLogin(e)}
                      >
                          login
                      </Button>              
                      </form>
                  </div>
              </Container>
            </main>
            <Snackbar
                open={open}
                onClose={() => setOpen(!open)}
            >
                <Alert onClose={() => setOpen(!open)} severity="error">
                {errorMsg}
                </Alert>
            </Snackbar>
            <FooterComponent />
            </div>
        );
    }
}

const mapDispatchToProps = (dispatch: Dispatch<UserActionTypes>) =>
  bindActionCreators(
    {
      saveToken
    },
    dispatch
);

export default connect(
    null,
    mapDispatchToProps
  )(EmailConfirmPage);